import Vuex from "vuex";
import Vue from "vue";

import AbsenceData, { ABSENCE_DATA_MODULE_NAME } from "./modules/absenceData";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    [ABSENCE_DATA_MODULE_NAME]: new AbsenceData()
  }
});
