<template>
  <div class="not-found-page">
    <h1>
      404 - Not Found
    </h1>
    <v-img :src="selectedImage" class="not-found-image"> </v-img>
    <span class="not-found-copyright">
      created with Meme Generator by ZomboDroid
    </span>
    <div>
      <v-btn
        class="not-found-button"
        depressed
        large
        color="primary"
        @click="$router.go(-1)"
        >Go back!</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
// Vue imports
import Vue from "vue";
import Component from "vue-class-component";

// Local component declarations
@Component
export default class NotFound extends Vue {
  notFoundImages = [
    require("../../assets/404/404_1.png"),
    require("../../assets/404/404_2.png"),
    require("../../assets/404/404_3.png"),
    require("../../assets/404/404_4.png"),
    require("../../assets/404/404_5.png"),
    require("../../assets/404/404_6.png"),
    require("../../assets/404/404_7.png"),
    require("../../assets/404/404_8.png"),
    require("../../assets/404/404_9.png"),
    require("../../assets/404/404_11.png"),
    require("../../assets/404/404_12.png"),
    require("../../assets/404/404_13.png"),
    require("../../assets/404/404_14.png"),
    require("../../assets/404/404_15.png"),
    require("../../assets/404/404_16.png"),
    require("../../assets/404/404_17.png"),
    require("../../assets/404/404_18.png"),
    require("../../assets/404/404_19.png"),
    require("../../assets/404/404_20.png"),
    require("../../assets/404/404_21.png"),
    require("../../assets/404/404_22.png"),
    require("../../assets/404/404_23.png"),
    require("../../assets/404/404_24.png"),
    require("../../assets/404/404_25.png"),
    require("../../assets/404/404_26.png")
  ];

  selectedImage = this.notFoundImages[
    Math.floor(Math.random() * this.notFoundImages.length)
    ];
}
</script>

<style scoped lang="scss">
.not-found-page {
  font-size: large;
  display: block;
}

.not-found-image {
  position: relative;
  border: solid black;
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  max-width: 30%;
}

.not-found-copyright {
  font-size: 50%;
  height: 0;
}
</style>
