import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import YearlyOverview from "./components/overview/yearlyOverview.vue";
import MonthlyOverview from "./components/overview/monthlyOverview.vue";
import NotFound from "./components/errors/notFound.vue";
import Forbidden from "./components/errors/forbidden.vue";
import UpdateUserInformation from "./components/updateUserInformation.vue";
import ChangeHistory from "./components/changeHistory.vue";
import YearlyEntitlement from "./components/entitlement/yearlyEntitlement.vue";
import VacationRequest from "./components/requests/vacationRequest.vue";
import VacationResponse from "./components/responses/vacationResponse.vue";
import AbsenceRequestsOverview from "./components/requestOverviews/absenceRequestsOverview.vue";
import WorkingHoursOverview from "./components/workingHoursOverview.vue";
import BusinessTripRequest from "./components/requests/businessTripRequest.vue";
import BusinessTripResponse from "./components/responses/businessTripResponse.vue";
import BusinessTripsOverview from "./components/requestOverviews/businessTripsOverview.vue";
import AbsenceOverview from "./components/overview/absenceOverview.vue";

Vue.use(VueRouter);

function routeToCurrentYear() {
  return `/overview/year/${new Date().getFullYear()}`;
}

function routeToCurrentMonth() {
  const currentDate = new Date();
  // counting months from 0 is somewhat counter-intuitive so I have to add 1 here
  return `/overview/month/${currentDate.getFullYear()}/${currentDate.getMonth() +
    1}`;
}

function routeToCurrentUser() {
  return `/workingHours/`;
}

function routeToCurrentYearEntitlement() {
  return `/yearlyEntitlement/${new Date().getFullYear()}`;
}

const routes: RouteConfig[] = [
  {
    path: "/",
    redirect: routeToCurrentYear
  },
  {
    path: "/overview/year/:year",
    name: "yearlyOverviewRoute",
    component: YearlyOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/overview/year**",
    redirect: routeToCurrentYear
  },
  {
    path: "/overview/month/:year/:month",
    name: "monthlyOverviewRoute",
    component: MonthlyOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/overview/month**",
    redirect: routeToCurrentMonth
  },
  {
    path: "/absenceRequests",
    component: AbsenceRequestsOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/businessTrips",
    component: BusinessTripsOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/respondBusinessTrip",
    component: BusinessTripResponse,
    meta: { requiresAuth: true }
  },
  {
    path: "/workingHours/:userId",
    component: WorkingHoursOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/workingHours**",
    component: WorkingHoursOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/updateUserInformation",
    component: UpdateUserInformation,
    meta: { requiresAuth: true }
  },
  {
    path: "/yearlyEntitlement/:year",
    name: "yearlyEntitlementRoute",
    component: YearlyEntitlement,
    meta: { requiresAuth: true }
  },
  {
    path: "/absence",
    name: "absenceRoute",
    component: AbsenceOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/yearlyEntitlement**",
    redirect: routeToCurrentYearEntitlement
  },
  {
    path: "/changeHistory",
    component: ChangeHistory,
    meta: { requiresAuth: true }
  },
  {
    path: "/vacationRequest",
    component: VacationRequest,
    meta: { requiresAuth: true }
  },
  {
    path: "/businessTrip",
    component: BusinessTripRequest,
    meta: { requiresAuth: true }
  },
  {
    path: "/respondAbsenceRequest",
    component: VacationResponse,
    meta: { requiresAuth: false }
  },
  {
    path: "/forbidden",
    name: "forbiddenPage",
    component: Forbidden,
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    name: "notFoundPage",
    component: NotFound,
    meta: { requiresAuth: true }
  }
];

export default new VueRouter({
  mode: "history",
  routes
});
