<template>
  <v-menu
    ref="menuActive"
    :return-value.sync="internalSelectedDate"
    :close-on-content-click="false"
    v-model="internalMenuActive"
    offset-y
    min-width="290px"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="internalTextInput"
        :class="datePickerType + '-input'"
        prepend-icon="event"
        :label="label"
        v-on="on"
        @keydown.native.enter="handleDateInput($event)"
        @blur="onTextInputBlur($event)"
      ></v-text-field>
    </template>

    <v-date-picker
      :class="datePickerType + '-picker'"
      :value="internalSelectedDate"
      no-title
      scrollable
      :allowed-dates="isDateAllowed"
      :first-day-of-week="1"
      @change="saveDate"
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  DATE_WITH_WEEKDAY_DATEFORMAT,
  YEAR_MONTH_DAY_DATEFORMAT
} from "@/constants";
import { formatDateToFormatStringInUTCTz } from "@/utils";
import { isValid } from "date-fns";
import { toDate } from "date-fns-tz";

const UPDATE_SELECTED_DATE = "update:selectedDate";

@Component
export default class DatePicker extends Vue {
  @Prop({ default: false }) menuActive!: boolean;
  @Prop({ default: "" }) selectedDate!: Date | null;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: true }) isDateAllowed?: (date: string) => boolean;
  @Prop() datePickerType!: "to-date" | "from-date";

  internalMenuActive: boolean = false;
  internalSelectedDate: Date | null = null;
  internalTextInput: string | null = null;

  @Watch("menuActive")
  onMenuActiveChange(newVal: boolean) {
    this.internalMenuActive = newVal;
  }

  @Watch("selectedDate")
  onSelectedDateChange(newVal: Date | null) {
    this.internalSelectedDate = newVal;
    if (!newVal) {
      return;
    }
    this.internalTextInput = formatDateToFormatStringInUTCTz(
      newVal,
      DATE_WITH_WEEKDAY_DATEFORMAT
    );
  }

  created() {
    this.internalMenuActive = this.menuActive;
    this.internalSelectedDate = this.selectedDate;
  }

  parseDate(dateString: string) {
    // @ts-ignore
    const date = new Date(
      dateString
        .slice(3)
        .split(".")
        .reverse()
        .join(".")
    );
    return formatDateToFormatStringInUTCTz(date, YEAR_MONTH_DAY_DATEFORMAT);
  }

  handleDateInput(event: KeyboardEvent) {
    if (this.internalTextInput === null) {
      return;
    }
    const dateString = toDate(this.internalTextInput);
    if (event.key !== "Enter" || this.internalTextInput === "") {
      return;
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return;
    }
    if (!this.internalIsDateAllowed(date.toString())) {
      return;
    }

    this.saveDate(dateString);
  }

  onTextInputBlur(event: Event) {
    if (this.internalTextInput === null) {
      return;
    }

    if (this.internalSelectedDate && isValid(this.internalSelectedDate)) {
      this.internalTextInput = this.formattedDate;
      this.$forceUpdate();
    }

    const dateString = this.parseDate(this.internalTextInput);

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return;
    }

    if (!this.internalIsDateAllowed(date.toString())) {
      return;
    }
  }

  saveDate(date: Date) {
    if (date === null) {
      return;
    }
    this.internalSelectedDate = date;
    // @ts-ignore
    this.$refs.menuActive.save(this.internalSelectedDate);
    this.internalMenuActive = false;
    this.$emit(UPDATE_SELECTED_DATE, this.internalSelectedDate);

    this.internalTextInput = this.formattedDate;
  }

  internalIsDateAllowed = (date: string) => {
    if (this.isDateAllowed) {
      return this.isDateAllowed(date);
    }
    return true;
  }

  get formattedDate() {
    if (!this.internalSelectedDate) {
      return "";
    }
    return formatDateToFormatStringInUTCTz(
      this.internalSelectedDate,
      DATE_WITH_WEEKDAY_DATEFORMAT
    );
  }
}
</script>

<style lang="scss"></style>
