<template>
  <div>
    <v-snackbar
      :value="snackbar"
      :color="transactionError ? 'error' : 'success'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="onCloseSnackbar"> Schließen</v-btn>
    </v-snackbar>

    <v-dialog :v-if="loading" persistent fullscreen full-width>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5);"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate size="64" />
        </v-layout>
      </v-container>
    </v-dialog>

    <v-expansion-panel expand :value="[true]">
      <v-expansion-panel-content
        v-for="req in requests"
        :key="req.businessTripId"
        :data-employee-id="req.employeeId"
        :data-absence-request-id="req.businessTripId"
        readonly
        hideActions
      >
        <template v-slot:header>
          <div
            class="expandable-header"
            :class="header.className"
            v-for="(header, i) of getExpandableHeaderData(req)"
            :key="i"
          >
            <div class="expandable-header-column">
              <div class="header-title">{{ header.title }}</div>
              <div class="header-value">{{ header.value }}</div>
            </div>
          </div>
        </template>

        <div class="expandable-body">
          <div class="expandable-body-column absence-request-note">
            <span>Zweck:</span>
            {{ req.purpose }}
          </div>
        </div>
        <div
          class="expandable-body"
          v-for="(body, recipientIndex) of getExpandableBodyData(req)"
          :data-recipient-id="req.recipients[recipientIndex].recipientId"
          :key="recipientIndex"
        >
          <div
            class="expandable-body-column"
            v-for="(entry, k) of body"
            :class="entry.className"
            :key="k"
          >
            <div>{{ entry.value }}</div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script lang="ts">
// Vue imports
import { formatDateToFormatStringInUTCTz, getDateContext } from "@/utils";
import Vue from "vue";
import Component from "vue-class-component";
import { BusinessTripResponseOverview, BusinessTripState } from "@/models";
import { DATE_WITH_WEEKDAY_DATEFORMAT, statusNamesByKey } from "@/constants";

type ExpandableHeader = Array<{
  title: string;
  value: string;
  className: string;
}>;
type ExpandableBody = Array<{ className: string; value: string | boolean }>;

// Local component declarations
@Component
export default class BusinessTripResponse extends Vue {
  token: string = "";
  response: boolean = false;
  loading: boolean = true;
  requests: BusinessTripResponseOverview[] = [];

  transactionStatus = "";
  snackbar = false;
  transactionError = false;

  mounted() {
    this.token = String(this.$route.query.token);
    this.response = String(this.$route.query.response).toLowerCase() === "true";
    this.sendResponse();
  }

  sendResponse() {
    const searchParams = new URLSearchParams(window.location.search);
    const queryToken = searchParams.get("token");
    const queryResponse = searchParams.get("response");

    if (queryToken === null || queryResponse === null) {
      this.transactionStatus =
        "Diese Antwort beinhaltet nicht alle benötigten Parameter";
      this.transactionError = true;
      this.snackbar = true;
      return;
    }

    const endpointUrl = `/api/business-trips/answer?token=${queryToken}&response=${queryResponse}`;

    this.axios
      .patch(endpointUrl)
      .then(response => {
        this.requests.push(response.data);
        this.loading = false;

        this.transactionStatus = this.response
          ? "Geschäftsreise genehmigt"
          : "Geschäftsreise abgelehnt";
        this.snackbar = true;
      })
      .catch(reason => {
        this.transactionStatus = reason.response.data;
        this.transactionError = true;
        this.snackbar = true;

        this.loading = false;
      });
  }

  getExpandableHeaderData(
    businessTrip: BusinessTripResponseOverview
  ): ExpandableHeader {
    return [
      ...[
        {
          title: "Ersteller*in",
          value: businessTrip.employeeName,
          className: "posted-by"
        }
      ],
      {
        title: "Gestellt Am",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(businessTrip.createdAt),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `posted-on`
      },
      {
        title: "Von",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(businessTrip.startDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `from-date`
      },
      {
        title: "Bis",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(businessTrip.endDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `to-date`
      },
      {
        title: "Status",
        value: this.getFormattedRequestStatusOption(businessTrip.state),
        className: "request-status"
      }
    ];
  }

  getExpandableBodyData({
                          recipients = []
                        }: BusinessTripResponseOverview): ExpandableBody[] {
    return recipients.map(({ email, state }) => {
      return [
        {
          className: "recipient",
          value: email
        },
        {
          className: "recipient-status",
          value: statusNamesByKey[state]
        }
      ];
    });
  }

  getFormattedRequestStatusOption(reqStatus: BusinessTripState) {
    return statusNamesByKey[reqStatus];
  }

  onCloseSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }
}
</script>

<style lang="scss">
.v-expansion-panel__container {
  /* stylelint-disable */
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  /* stylelint-enable */
  margin-bottom: 0.25%;

  .expandable-header {
    &.posted-by,
    &.posted-on,
    &.from-date,
    &.to-date {
      flex: 0 0 22%;

      &.with-extra-space {
        flex: 0 0 29%;
      }
    }

    &.request-status {
      flex: 0 0 10%;
    }

    .header-title {
      font-weight: bold;
      padding-bottom: 5.5%;
    }
  }

  .expandable-header,
  .expandable-body {
    display: flex;
    justify-content: space-between;
  }

  .expandable-body {
    padding: 0 24px;
    align-items: center;
    border-top: 1px solid lightgrey;
  }

  .expandable-body-column {
    flex: 0 0 25%;

    &.absence-request-note {
      flex: 0 0 100%;
      padding: 16px 12px;

      span {
        display: block;
        font-weight: bold;
        margin-right: 1.5%;
      }
    }
  }

  .recipient-status {
    flex: 0 0 20%;
  }

  .expandable-header-column,
  .expandable-body-column {
    padding: 12px;
  }
}
</style>
