// Reg Exp

export const REG_EXP_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Week Day Names
export const WEEK_DAY_MONDAY = "Monday";
export const WEEK_DAY_TUESDAY = "Tuesday";
export const WEEK_DAY_WEDNESDAY = "Wednesday";
export const WEEK_DAY_THURSDAY = "Thursday";
export const WEEK_DAY_FRIDAY = "Friday";
export const WEEK_DAY_SATURDAY = "Saturday";
export const WEEK_DAY_SUNDAY = "Sunday";

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export const TIME_YEARS = "years";
export const TIME_MONTHS = "months";
export const TIME_WEEKS = "weeks";
export const TIME_DAYS = "days";
export const TIME_HOURS = "hours";
export const TIME_MINUTES = "minutes";
export const TIME_SECONDS = "seconds";

export const timeUnits = [
  TIME_YEARS,
  TIME_MONTHS,
  TIME_WEEKS,
  TIME_DAYS,
  TIME_HOURS,
  TIME_MINUTES,
  TIME_SECONDS
];

export const DATEPICKER_INPUT_DATE_FORMAT: string = "dd.MM.yyyy";
export const TABLE_HEADER_DATE_FORMAT: string = "EEEE. d MMM";
export const TABLE_HEADER_DATE_FORMAT_MOBILE: string = "d";

export const DATE_WITH_WEEKDAY_DATEFORMAT = "EEEEEE d.MM.yyyy";
export const YEAR_MONTH_DAY_DATEFORMAT = "yyyy-MM-dd";
export const DEFAULT_TIMEZONE = "Europe/Berlin";

export const MONTH_AS_WORD_WITH_YEAR_DATEFORMAT = "MMMM yyyy";
export const timeUnitsByKey: { [key: string]: string } = {
  [TIME_SECONDS]: "Sekunden",
  [TIME_MINUTES]: "Minuten",
  [TIME_HOURS]: "Stunden",
  [TIME_DAYS]: "Tage",
  [TIME_WEEKS]: "Wochen",
  [TIME_MONTHS]: "Monate",
  [TIME_YEARS]: "Jahre"
};

export const MONTH_JANUARY = "january";
export const MONTH_FEBRUARY = "february";
export const MONTH_MARCH = "march";
export const MONTH_APRIL = "april";
export const MONTH_MAY = "may";
export const MONTH_JUNE = "june";
export const MONTH_JULY = "july";
export const MONTH_AUGUST = "august";
export const MONTH_SEPTEMBER = "september";
export const MONTH_OCTOBER = "october";
export const MONTH_NOVEMBER = "november";
export const MONTH_DECEMBER = "december";

export const ALL_MONTHS = [
  MONTH_JANUARY
  ,MONTH_FEBRUARY
  ,MONTH_MARCH
  ,MONTH_APRIL
  ,MONTH_MAY
  ,MONTH_JUNE
  ,MONTH_JULY
  ,MONTH_AUGUST
  ,MONTH_SEPTEMBER
  ,MONTH_OCTOBER
  ,MONTH_NOVEMBER
  ,MONTH_DECEMBER];

export const monthNamesByKey: { [key: string]: string } = {
  [MONTH_JANUARY]: "Jan",
  [MONTH_FEBRUARY]: "Feb",
  [MONTH_MARCH]: "März",
  [MONTH_APRIL]: "Apr",
  [MONTH_MAY]: "Mai",
  [MONTH_JUNE]: "Juni",
  [MONTH_JULY]: "Juli",
  [MONTH_AUGUST]: "Aug",
  [MONTH_SEPTEMBER]: "Sep",
  [MONTH_OCTOBER]: "Okt",
  [MONTH_NOVEMBER]: "Nov",
  [MONTH_DECEMBER]: "Dez"
};

export const DEFAULT_EMAIL = "vertrieburlaub@flavia-it.de";

export const PARENTAL_LEAVE = "Elternzeit";
export const HOLIDAY_LEAVE = "Feiertag";
export const CHILD_SICK_LEAVE = "Kindkrank";
export const SICK_LEAVE = "Krank";
export const SPECIAL_LEAVE = "Sonderurlaub";
export const UNPAID_ABSENCE = "Unbezahlte Abwesenheit";
export const HALF_DAY_LEAVE = "Urlaub halber Tag";
export const FULL_DAY_LEAVE = "Urlaub ganzer Tag";
export const COMPENSATORY_TIME = "Zeitausgleich";

export const VACATION_TYPES: string[] = [
  PARENTAL_LEAVE,
  HOLIDAY_LEAVE,
  CHILD_SICK_LEAVE,
  SICK_LEAVE,
  SPECIAL_LEAVE,
  UNPAID_ABSENCE,
  HALF_DAY_LEAVE,
  FULL_DAY_LEAVE,
  COMPENSATORY_TIME
];

export enum VacationTypesByAbbreviation {
  EZ,
  F,
  KK,
  K,
  S,
  UAW,
  u,
  U,
  A
}

export const STATUS_REQUESTED = "REQUESTED";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_DECLINED = "DECLINED";
export const STATUS_ACCEPTED = "ACCEPTED";
export const STATUS_CANCELLED = "CANCELLED";
export const STATUS_NOT_PROCESSED = "NOT_PROCESSED";
export const STATUS_PROCESSED = "PROCESSED";
export const STATUS_CANCELLATION_REQUESTED = "CANCELLATION_REQUESTED";

export const RecipientStatuses = [
  STATUS_REQUESTED,
  STATUS_DECLINED,
  STATUS_APPROVED
];
export const AbsenceRequestStatuses = [
  STATUS_REQUESTED,
  STATUS_DECLINED,
  STATUS_CANCELLED,
  STATUS_NOT_PROCESSED,
  STATUS_PROCESSED,
  STATUS_CANCELLATION_REQUESTED
];

export const BusinessTripStatuses = [
  STATUS_REQUESTED,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_CANCELLED,
  STATUS_CANCELLATION_REQUESTED
];

export const statusNamesByKey: { [key: string]: string } = {
  [STATUS_REQUESTED]: "Angefragt",
  [STATUS_APPROVED]: "Genehmigt",
  [STATUS_ACCEPTED]: "Genehmigt",
  [STATUS_DECLINED]: "Abgelehnt",
  [STATUS_CANCELLED]: "Storniert",
  [STATUS_NOT_PROCESSED]: "Nicht verarbeitet",
  [STATUS_PROCESSED]: "Verarbeitet",
  [STATUS_CANCELLATION_REQUESTED]: "Stornierung beantragt"
};

export const restrictedRoutes = [
  {
    name: "Jahresansprüche",
    path: "/yearlyEntitlement"
  },
  {
    name: "Personeninformationen",
    path: "/updateUserInformation"
  },
  {
    name: "Soll-Arbeitszeiten",
    path: "/workingHours"
  },
  {
    name: "Abwesenheit",
    path: "/absence"
  }
];

export const publicRoutes = [
  {
    name: "Urlaubsantrag stellen",
    path: "/vacationRequest"
  },
  {
    name: "Geschäftsreise beantragen",
    path: "/businessTrip"
  },
  {
    name: "Jahresübersicht",
    path: "/overview/year"
  },
  {
    name: "Monatsübersicht",
    path: "/overview/month"
  },
  {
    name: "Urlaubsanträge",
    path: "/absenceRequests"
  },
  {
    name: "Geschäftsreisen",
    path: "/businessTrips"
  },
  {
    name: "Änderungshistorie",
    path: "/changeHistory"
  }
];

export const ROLE_STUDENT = "student";
export const ROLE_DEVELOPER = "developer";
export const ROLE_MANAGER = "manager";
export const ROLE_OTHER = "other";

export const userRolesByKey: { [key: string]: string } = {
  [ROLE_STUDENT]: "Nachwuchs",
  [ROLE_DEVELOPER]: "Entwicklung",
  [ROLE_MANAGER]: "Verwaltung",
  [ROLE_OTHER]: "Sonstige"
};

export const userRoleKeysByValue: { [key: string]: string } = {
  Nachwuchs: ROLE_STUDENT,
  Entwicklung: ROLE_DEVELOPER,
  Verwaltung: ROLE_MANAGER,
  Sonstige: ROLE_OTHER
};

export const PLACEHOLDER_NO_SELECTABLE_DATA =
  "Es gibt keine weiteren Auswahlmöglichkeiten";

export const PLACEHOLDER_NO_SELECTABLE_DATA_ALLOW_NEW =
  "Neue Email eingeben und mit Enter bestätigen";

export const MINIMAL_RESPONSIVE_WIDTH = 750;
export const MOBILE_WARNING =
  "Captain Balance sollte auf einem Desktop-Gerät verwendet werden";
