var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"change-history-layout"},[_c('v-snackbar',{attrs:{"color":_vm.transactionError ? 'error' : 'success',"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.transactionStatus)+" "),_c('v-btn',{attrs:{"color":"white","flat":""},on:{"click":function($event){return _vm.closeSnackbar()}}},[_vm._v(" Schließen")])],1),(_vm.userHasReadRights)?_c('v-layout',{attrs:{"justify-center":"","row":""}},[_c('v-flex',[_c('v-spacer')],1),_c('v-flex',[_c('v-autocomplete',{attrs:{"value":_vm.selectedUser,"type":'User',"disabled":!_vm.usersLoaded,"loading":!_vm.usersLoaded,"items":_vm.users,"item-text":_vm.userItemText,"return-object":"","label":"Person","no-data-text":_vm.noDataPlaceholder,"required":"","hide-selected":""},on:{"change":user=>_vm.onChangeUser(user)}})],1),_c('v-flex',[_c('v-spacer')],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.changeHistoryLoaded),expression:"changeHistoryLoaded"}]},[_c('span',{staticClass:"sort-text"},[_vm._v("Sortierung:")]),_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.reverseSorting}},[_vm._v(" "+_vm._s((_vm.sortOldestFirst ? "Ält" : "Neu") + "este zuerst")+" ")]),_c('v-data-table',{staticClass:"absence-table text-sm-center",attrs:{"headers":_vm.tableHeaders,"items":_vm.changeHistory,"hide-actions":!!_vm.changeHistory ? _vm.changeHistory.length <= 30 : true,"rows-per-page-items":[
        30,
        { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }
      ],"disable-initial-sort":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.header.text))])]}},{key:"items",fn:function(props){return [(props.item.creator !== null)?_c('td',[_vm._v(" "+_vm._s(props.item.creator.lastName)+", "+_vm._s(props.item.creator.firstName)+" ")]):_c('td',[_vm._v(" SYSTEM ")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(props.item.creationDate)))]),_c('td',[_vm._v(_vm._s(props.item.year))]),_c('td',{class:_vm.addedOrRemovedClass(props.item.sumDifference)},[_vm._v(" "+_vm._s(props.item.sum + _vm.formatDifference(props.item.sumDifference))+" ")]),_c('td',{class:_vm.addedOrRemovedClass(props.item.yearlyEntitlementDifference)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(props.item.yearlyEntitlement) + _vm.formatDifference(props.item.yearlyEntitlementDifference))+" ")]),_c('td',{class:_vm.addedOrRemovedClass(props.item.remainingVacationDifference)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(props.item.remainingVacation) + _vm.formatDifference(props.item.remainingVacationDifference))+" ")]),_c('td',{class:_vm.addedOrRemovedClass(
              props.item.remainingVacationFromLastYearDifference
            )},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(props.item.remainingVacationFromLastYear) + _vm.formatDifference( props.item.remainingVacationFromLastYearDifference ))+" ")]),_c('td',{class:_vm.addedOrRemovedClass(props.item.sicknessDifference)},[_vm._v(" "+_vm._s(props.item.sickness + _vm.formatDifference(props.item.sicknessDifference))+" ")]),_c('td',{class:_vm.addedOrRemovedClass(props.item.childSickDifference)},[_vm._v(" "+_vm._s(props.item.childSick + _vm.formatDifference(props.item.childSickDifference))+" ")]),_c('td',{class:_vm.addedOrRemovedClass(props.item.workingHoursDeleted)},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400","disabled":!props.item.workingHoursInMinutes},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.formatWorkingHoursInMinutes(props.item.workingHoursInMinutes)))])]}}],null,true)},[(props.item.workingHoursInMinutes)?_c('div',{staticClass:"tooltip-text"},[_c('span',[_vm._v(_vm._s(_vm.formatWorkingHoursInMinutes(props.item.workingHoursInMinutes)))]),_c('span',[_vm._v("Gültig ab: "+_vm._s(_vm.getDateContext( props.item.workingHoursValidFrom ).toLocaleDateString(_vm.DATEPICKER_INPUT_DATE_FORMAT)))]),(props.item.workingHoursDeleted)?_c('span',[_vm._v("Diese Soll-Arbeitszeit wurde gelöscht")]):_vm._e()]):_vm._e()])],1),_c('td',{ref:`remark_${props.item.creationDate}`,staticClass:"remark",class:{
            ellipsized: _vm.isOverflowingTableCell(
              `remark_${props.item.creationDate}`
            )
          }},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.remarkCellsOverflowStates[`remark_${props.item.creationDate}`],"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"remark-text"},on),[_vm._v(_vm._s(props.item.remark ? props.item.remark : "—"))])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(props.item.remark ? props.item.remark : "—"))])])],1),_c('td',[_c('v-tooltip',{attrs:{"dark":"","left":"","disabled":Object.values(props.item.changesInMonths).filter(val => !!val)
                .length !== 0},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"disabled":Object.values(props.item.changesInMonths).filter(
                      val => !!val
                    ).length === 0,"large":""},on:{"click":function($event){return _vm.openDetailView(props.item)}}},[_vm._v(" pageview ")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v("Es gab in keinem Monat eine Änderung")])])],1)]}}])})],1),_c('v-dialog',{attrs:{"lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-layout',{attrs:{"justify-center":"","row":""}},[_c('v-flex',[_c('h2',[_vm._v("Detail Ansicht")])])],1)],1),_c('v-card-text',[(_vm.entryInDetailView)?_c('v-data-table',{staticClass:"absence-table overview text-sm-center",attrs:{"headers":_vm.detailHeaders,"hide-actions":"","rows-per-page-items":[12],"items":_vm.itemsForDetailView(_vm.entryInDetailView.changesInMonths)},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.header.text))])]}},{key:"items",fn:function(props){return _vm._l((props.item),function(change,index){return _c('td',{key:index,class:change.absenceChange.toLowerCase()},[_c('v-tooltip',{attrs:{"dark":"","left":"","disabled":!change.typeBeforeChange},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({class:change.absenceChange === 'NO_CHANGE'
                        ? ''
                        : ' heavier-font'},on),[_vm._v(_vm._s(_vm.contentForAbsenceChange(change)))])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v("Vorher: '"+_vm._s(change.typeBeforeChange)+"'")])])],1)})}}],null,false,4060361838)}):_vm._e()],1),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":"","row":""}},[_c('v-flex',{attrs:{"grow":""}},[_c('v-spacer')],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Schließen")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }