<template>
  <RequestForm :fetch-preselected-recipients="fetchRecipientsOfLatestRequestByUser"
               endpoint-url="/api/business-trips"
               place-holder-user-selection="Bitte für dein Projekt zuständige Person auswählen"
               comment-label="Anlass der Reise. Erforderliche Nähe des Hotels zu einer bestimmten Adresse, etc."
               comment-required-message="Es muss ein Anlass der Reise angegeben werden"
               submit-button-label="Neue Geschäftsreise beantragen"
               :restrict-users="RESTRICT_USERS"
               allow-weekend>
  </RequestForm>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

import {BusinessTripRecipient} from "@/models";
import RequestForm from "../requests/requestForm.vue";

@Component({
  components: {
    RequestForm,
  }
})
export default class BusinessTrip extends Vue {
  // only Management can allow business trips
  RESTRICT_USERS: string[] = [
    "tobias.kilian@flavia-it.de",
    "max.schulze@flavia-it.de",
    "luis.pazos@flavia-it.de",
    "ines.windt@flavia-it.de",
    "kilian.hausmanns@flavia-it.de"
  ];

  async fetchRecipientsOfLatestRequestByUser(): Promise<BusinessTripRecipient[]> {
    return this.axios
      .get(`/api/business-trips/latest`)
      .then(response => (response.data) || []);
  }
}
</script>
