var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"working-hours-overview"},[_c('v-snackbar',{attrs:{"color":_vm.transactionError ? 'error' : 'success',"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.transactionStatus)+" "),_c('v-btn',{attrs:{"color":"white","flat":""},on:{"click":function($event){return _vm.onCloseSnackbar()}}},[_vm._v(" Schließen")])],1),_c('v-layout',{staticClass:"users-select-box-wrapper",attrs:{"justify-center":"","row":""}},[_c('v-flex',[_c('v-spacer')],1),_c('v-flex',{staticClass:"users-select-box"},[_c('v-autocomplete',{attrs:{"value":_vm.selectedUser,"type":'User',"disabled":!_vm.usersLoaded,"loading":!_vm.usersLoaded,"items":_vm.users,"item-text":_vm.userItemText,"return-object":"","label":"Person","no-data-text":_vm.getNoDataPlaceholder(),"required":"","hide-selected":""},on:{"change":user=>_vm.onChangeUser(user)}})],1),_c('v-flex',[_c('v-spacer')],1)],1),(_vm.userWorkingHoursLoaded)?_c('div',{staticClass:"table-content-data"},[_c('div',{staticClass:"working-hours-data-table-wrapper"},[_c('v-data-table',{staticClass:"working-hours-data-table",attrs:{"headers":_vm.dataTableHeaders,"items":_vm.userWorkingHours,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.header.text))])]}},{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(_vm.getFormattedDate(props.item.validFrom)))]),_c('td',[_vm._v(" "+_vm._s(_vm.formatWorkingHoursInMinutes(props.item.weeklySumInMinutes))+" ")]),_c('td',[(props.item.hourlyRatedWage)?_c('v-icon',[_vm._v("check")]):_c('v-icon',[_vm._v("close")])],1),_c('td',[_c('v-btn',{staticClass:"delete-working-hours-entry",attrs:{"slot":"activator","icon":"","disabled":!_vm.userMetaData.isAdmin ||
                  !_vm.isLatestEntry(props.item) ||
                  _vm.loadingForm},on:{"click":() => _vm.openEntitlementForm(props.item)},slot:"activator"},[_c('v-icon',[_vm._v("delete")])],1)],1)]}}],null,false,733914549)})],1),(_vm.userMetaData.isAdmin)?_c('div',{staticClass:"add-working-hours-wrapper"},[_c('h3',[_vm._v("Neue Soll-Arbeitszeit eintragen")]),_c('v-form',{staticClass:"add-working-hours-form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"working-hours-date-picker"},[[_c('date-picker',{attrs:{"label":"Gültig ab","menu-active":_vm.showDatePicker,"selected-date":_vm.newWorkingHoursValidFrom,"is-date-allowed":_vm.noExistingEntryOnDate},on:{"update:menuActive":function($event){_vm.showDatePicker = $event},"update:selectedDate":function($event){_vm.newWorkingHoursValidFrom = $event}},model:{value:(_vm.newWorkingHoursValidFrom),callback:function ($$v) {_vm.newWorkingHoursValidFrom=$$v},expression:"newWorkingHoursValidFrom"}})]],2),_c('div',{staticClass:"working-hours-sum-input"},[_c('v-text-field',{staticClass:"working-hours-sum",attrs:{"type":"string","rules":[
              v => (!!v && v.trim().length > 0) || 'Arbeitszeit erforderlich',
              v =>
                _vm.testWorkingHoursInput(v) ||
                'Eingabe muss folgendes Format haben: 38h 30m'
            ],"label":"Arbeitszeit pro Woche"},model:{value:(_vm.newWorkingHoursString),callback:function ($$v) {_vm.newWorkingHoursString=$$v},expression:"newWorkingHoursString"}})],1),_c('div',{staticClass:"hourly-rated-wage-checkbox"},[_c('v-checkbox',{staticClass:"hourly-rated-wage",attrs:{"type":"string","label":"Stundenbasierte Abrechnung"},model:{value:(_vm.newHourlyRatedWage),callback:function ($$v) {_vm.newHourlyRatedWage=$$v},expression:"newHourlyRatedWage"}})],1),_c('div',{staticClass:"add-new-working-hours-button"},[_c('v-btn',{attrs:{"disabled":!_vm.isFormValid || _vm.loadingForm,"color":"secondary"},on:{"click":() => _vm.openEntitlementForm(null)}},[_vm._v("Arbeitszeit eintragen ")])],1)])],1):_vm._e(),(_vm.selectedUser && _vm.shouldShowEntitlementForm)?_c('entitlement-form',{attrs:{"show-form":_vm.shouldShowEntitlementForm,"show-year-selection":false,"submit":_vm.submitEntitlementForm,"cancel":() => (_vm.shouldShowEntitlementForm = false),"initial-data":_vm.newEntitlementData,"title":`Urlaubsanspruch für ${_vm.selectedUser.firstName} ${_vm.selectedUser.lastName}
        in ${_vm.newEntitlementData.year}
        entsprechend der Arbeitszeitänderung anpassen`,"warning":!_vm.newEntitlementData.remainingEntitlement
          ? `Für die Person ist in ${_vm.newEntitlementData.year}
      noch kein Urlaubsanspruch hinterlegt.
      Um die Arbeitszeit einzutragen muss ein neuer Anspruch angelegt werden.`
          : ``}}):_vm._e()],1):_c('div',[_c('div',{attrs:{"id":"loadingSpinner"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }