<template>
  <div>
    <div class="filter-container">
      <user-filter
        class="filter-input"
        :updateUserSelection="loadData"
        :contentIsLoaded="requestHandled"
        :user-request-accuracy="{ year: null }"
        overview-type="yearly"
      ></user-filter>
    </div>
    <div
      v-if="userDataLoaded && userMetaData.userId"
      class="yearly-entitlement-layout"
    >
      <v-snackbar
        v-model="snackbar"
        :color="transactionError ? 'error' : 'success'"
        top
      >
        {{ transactionStatus }}
        <v-btn color="white" flat @click="closeSnackbar()">Schließen</v-btn>
      </v-snackbar>
      <v-data-table
        must-sort
        :headers="userDataHeaders"
        :items="getUsersToShow()"
        :sort-by.sync="sortBy"
        class="user-data-table-entitlement"
        :hide-actions="userDataLoaded"
      >
        <template v-slot:headerCell="props">
          <span>{{ props.header.text }}</span>
        </template>
        <template v-slot:items="props">
          <td class="users">
            {{ props.item.lastName }}, {{ props.item.firstName }}
          </td>
          <td v-for="adjacentYearKey in ['this', 'next']">
            <div
              v-if="userAbsencesMapsForAdjacentYears[adjacentYearKey][props.item.userId]">
              {{
                roundToTwoDecimals(
                  userAbsencesMapsForAdjacentYears[adjacentYearKey][
                    props.item.userId
                  ].remainingEntitlement
                )
              }}
              /
              {{
                roundToTwoDecimals(
                  userAbsencesMapsForAdjacentYears[adjacentYearKey][
                    props.item.userId
                  ].yearlyEntitlement
                )
              }}
              <v-btn v-if="userMetaData.isAdmin" icon slot="activator">
                <v-icon
                  class="change-user-data-btn"
                  color="primary"
                  @click="
                    changeEntitlementForSelectedUser(
                      props.item,
                      adjacentYearKey
                    )
                  "
                  >edit
                </v-icon>
              </v-btn>
            </div>
            <div v-else class="no-entitlement">
              {{ "Kein Eintrag" }}
              <v-btn v-if="userMetaData.isAdmin" icon slot="activator">
                <v-icon
                  class="add-user-data-btn"
                  color="primary"
                  @click="
                    addEntitlementForCurrentUser(props.item, adjacentYearKey)
                  "
                  >add
                </v-icon>
              </v-btn>
            </div>
          </td>
          <td class="working-hours">
            <div v-if="getUserWorkingHours(props.item.userId)">
              {{
                roundToTwoDecimals(
                  getUserWorkingHours(props.item.userId).weeklySumInMinutes / 60
                ) + "h"
              }}
              <v-btn v-if="userMetaData.isAdmin" icon slot="activator">
                <v-icon
                  class="change-user-data-btn"
                  color="primary"
                  @click="goToWorkingHoursPage(props.item.userId)"
                  >edit
                </v-icon>
              </v-btn>
            </div>
            <div v-else class="no-working-hours">
              {{ "Kein Eintrag" }}
              <v-btn v-if="userMetaData.isAdmin" icon slot="activator">
                <v-icon
                  class="add-user-data-btn"
                  color="primary"
                  @click="goToWorkingHoursPage(props.item.userId)"
                  >add
                </v-icon>
              </v-btn>
            </div>
          </td>
        </template>
      </v-data-table>
      <entitlement-form
        ref="entitlementDialogue"
        v-if="shouldShowEntitlementForm"
        :show-form="shouldShowEntitlementForm"
        :show-year-selection="false"
        :submit="saveEntitlement"
        :cancel="closeEntitlementForm"
        :title="
          `Urlaubsanspruch für ${userToEdit.firstName} ${userToEdit.lastName} ${createOrEditString}`
        "
        :initial-data="selectedUserData"
      ></entitlement-form>
    </div>
    <div v-else>
      <div id="loadingSpinner" v-if="!requestHandled"></div>

      <div v-else-if="userMetaData.userId">
        <div class="no-absence-date" v-if="!errorLoadingData">
          <strong>
            Es gibt keine Einträge für die ausgewählten Personen
          </strong>
        </div>

        <div v-else class="loading-data-error">
          <strong max-width="440">
            Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
            {{ errorLoadingData }}
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EntitlementForm from "../entitlement/entitlementForm.vue";
import UserFilter from "../common/userFilter.vue";
import { MOBILE_WARNING, userRolesByKey } from "@/constants";
import {
  Absences,
  AbsencesInYearlyOverview,
  EntitlementData,
  User,
  UserMetaData,
  WorkingHours
} from "@/models";
import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {
  getDateContext,
  getUserMessageForErrorReason,
  roundToTwoDecimals
} from "@/utils";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { startOfDay } from "date-fns";

interface UserAbsencesMap {
  [key: string]: AbsencesInYearlyOverview;
}

type AdjacentYearKey = "last" | "this" | "next";

const WORKING_HOURS_PATH = "/workingHours/";

@Component({
  components: { userFilter: UserFilter, entitlementForm: EntitlementForm }
})
export default class YearlyEntitlement extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  users!: User[];

  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAbsencesOfYear)
  fetchAbsencesOfYear!: ([year, users]: [number, string[]]) => Promise<
    Absences<AbsencesInYearlyOverview>
  >;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];

  requestHandled = false;
  errorLoadingData = null;
  transactionStatus = "";
  userRoleData: User[] = [];
  sortBy = "lastName";
  userDataLoaded = false;

  // relative to year in route param, NOT the current year as in `new Date().getFullYear()`
  absencesLastYear: Absences<AbsencesInYearlyOverview> = {};
  absencesThisYear: Absences<AbsencesInYearlyOverview> = {};
  absencesNextYear: Absences<AbsencesInYearlyOverview> = {};

  userWorkingHoursMap: Map<string, WorkingHours[]> = new Map();

  roundToTwoDecimals = roundToTwoDecimals;

  userAbsencesMapsForAdjacentYears: {
    [key in AdjacentYearKey]: UserAbsencesMap;
  } = {
    last: {},
    this: {},
    next: {}
  };

  userToEdit = {} as User;
  userDataHeaders = [
    {
      text: "Name",
      value: "lastName",
      sortable: true,
      class: "header"
    },
    {
      text: "",
      value: "entitlementThisYear",
      sortable: false,
      class: "header"
    },
    {
      text: "",
      value: "entitlementNextYear",
      sortable: false,
      class: "header"
    },
    {
      text: "Soll-Arbeitszeit",
      value: "workingHours",
      sortable: false,
      class: "header"
    }
  ];

  // no shouldRenderEntitlementForm since form should always be newly rendered when opening
  shouldShowEntitlementForm = false;
  selectedUserData = {} as EntitlementData;
  // $route isn't available here, the dummy value ensures that the var is never undefined
  yearFromRoute = 1970;
  createOrEditString: "anpassen" | "erstellen" = "erstellen";

  transactionError = false;
  snackbar = false;
  userRoles = { ...userRolesByKey };
  userRolesSort = Object.getOwnPropertyNames(this.userRoles);

  mounted() {
    this.loadData();

    if (this.isOnMobileBrowser() && this.userHasWritePermissions()) {
      this.transactionStatus = MOBILE_WARNING;
      this.snackbar = true;
      this.transactionError = true;
    }
  }

  @Watch("$route.params")
  loadData() {
    this.requestHandled = false;
    this.yearFromRoute = parseInt(this.$route.params.year, 10);
    this.userDataHeaders[1].text = `Urlaubsanspruch ${this.yearFromRoute}`;
    this.userDataHeaders[2].text = `Urlaubsanspruch ${this.yearFromRoute + 1}`;
    let usersToLoad: string[];

    if (
      this.getSelectedUsers.length > 0 &&
      (this.userMetaData.isAdmin ||
        this.userMetaData.isManager ||
        this.userMetaData.isReviewer)
    ) {
      usersToLoad = this.getSelectedUsers.map(user => user.userId);
    } else {
      usersToLoad = [this.userMetaData.userId];
    }

    if (process.env.NODE_ENV !== "test") {
      let promises: Array<Promise<any>> = [
        this.fetchAbsencesOfYear([this.yearFromRoute - 1, usersToLoad]),
        this.fetchAbsencesOfYear([this.yearFromRoute, usersToLoad]),
        this.fetchAbsencesOfYear([this.yearFromRoute + 1, usersToLoad])
      ];

      promises = promises.concat(this.fetchUserWorkingHours(usersToLoad));

      Promise.all(promises)
        .then(fetchedAbsences => {
          this.absencesLastYear = fetchedAbsences[0];
          this.absencesThisYear = fetchedAbsences[1];
          this.absencesNextYear = fetchedAbsences[2];

          this.sortAbsenceData();
          this.userDataLoaded = true;
        })
        .catch(reason => (this.errorLoadingData = reason))
        .finally(() => (this.requestHandled = true));
    } else {
      this.requestHandled = true;
    }
  }

  sortAbsenceData() {
    this.userAbsencesMapsForAdjacentYears = {
      last: {},
      this: {},
      next: {}
    };
    this.mapAbsencesToUserIds("last", this.absencesLastYear);
    this.mapAbsencesToUserIds("this", this.absencesThisYear);
    this.mapAbsencesToUserIds("next", this.absencesNextYear);
  }

  mapAbsencesToUserIds(
    adjacentYearKey: AdjacentYearKey,
    absences: Absences<AbsencesInYearlyOverview>
  ) {
    if (absences) {
      for (const userRole in this.userRoles) {
        if (absences[userRole]) {
          for (const absence of absences[userRole]) {
            this.userAbsencesMapsForAdjacentYears[adjacentYearKey][
              absence.user.userId
              ] = absence;
          }
        }
      }
    }
  }

  saveEntitlement(data: EntitlementData) {
    return this.axios
      .post("/api/changeUserEntitlement", {
        affectedEmployee: this.userToEdit.userId,
        ...data
      })
      .then(() => {
        this.transactionError = false;
        this.snackbar = true;
        this.transactionStatus =
          "Die Änderungen wurden erfolgreich eingetragen";
        this.closeEntitlementForm();
        this.loadData();
      })
      .catch(reason => {
        this.transactionError = true;
        this.snackbar = true;
        this.transactionStatus = getUserMessageForErrorReason(reason);
      });
  }

  getUsersToShow() {
    return this.getSelectedUsers && this.getSelectedUsers.length > 0
      ? this.getSelectedUsers
      : [];
  }

  closeEntitlementForm() {
    this.userToEdit = {} as User;
    this.shouldShowEntitlementForm = false;
  }

  addEntitlementForCurrentUser(
    userToEdit: User,
    adjacentYearKey: "this" | "next"
  ) {
    this.selectedUserData = {} as EntitlementData;
    this.selectedUserData.year =
      this.yearFromRoute + (adjacentYearKey === "this" ? 0 : 1);
    this.createOrEditString = "erstellen";

    // "previous" relative to selection
    const absenceMapPreviousYear = this.userAbsencesMapsForAdjacentYears[
      adjacentYearKey === "this" ? "last" : "this"
      ];
    if (absenceMapPreviousYear) {
      const userAbsenceDataFromPreviousYear =
        absenceMapPreviousYear[userToEdit.userId];
      if (userAbsenceDataFromPreviousYear) {
        this.selectedUserData.yearlyEntitlement =
          userAbsenceDataFromPreviousYear.yearlyEntitlement;
        this.selectedUserData.remainingEntitlement =
          userAbsenceDataFromPreviousYear.yearlyEntitlement;
      }
    }

    this.userToEdit = userToEdit;
    this.shouldShowEntitlementForm = true;
  }

  changeEntitlementForSelectedUser(
    userToEdit: User,
    adjacentYearKey: "this" | "next"
  ) {
    this.selectedUserData = this.userAbsencesMapsForAdjacentYears[
      adjacentYearKey
      ][userToEdit.userId];

    this.createOrEditString = "anpassen";

    this.userToEdit = userToEdit;
    this.shouldShowEntitlementForm = true;
  }

  goToWorkingHoursPage(userId: string) {
    this.$router.push(WORKING_HOURS_PATH + userId);
  }

  fetchUserWorkingHours(usersToLoad: string[]) {
    const promises: Array<Promise<any>> = [];
    usersToLoad.forEach(userId =>
      promises.push(
        this.axios
          .get(`/api/getUserWorkingHours/${userId}`)
          .then(response =>
            this.userWorkingHoursMap.set(
              userId,
              response.data as WorkingHours[]
            )
          )
      )
    );
    return promises;
  }

  getUserWorkingHours(userId: string) {
    const userWorkingHours:
      | WorkingHours[]
      | undefined = this.userWorkingHoursMap.get(userId);

    if (userWorkingHours) {
      const filteredUserWorkingHours:
        | WorkingHours[]
        | undefined = userWorkingHours.filter(workingHours => {
        const utilDate = new Date();
        const dateToCompare = new Date(utilDate.getTime());

        utilDate.setFullYear(workingHours.validFrom[0]);
        utilDate.setMonth(workingHours.validFrom[1] - 1);
        utilDate.setDate(workingHours.validFrom[2]);
        return startOfDay(utilDate).getTime() <= dateToCompare.getTime();
      });

      if (filteredUserWorkingHours) {
        return filteredUserWorkingHours
          .sort(
            (a, b) =>
              getDateContext(a.validFrom).getTime() -
              getDateContext(b.validFrom).getTime()
          )
          .pop();
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  closeSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  userHasWritePermissions(): boolean {
    return this.userMetaData.isAdmin || this.userMetaData.isManager;
  }
}
</script>

<style lang="scss">
.yearly-entitlement-layout {
  margin: 0 1.5%;

  .user-data-table-entitlement {
    .v-table {
      &.v-datatable {
        tbody {
          border-bottom: 1px solid;
        }

        td,
        th {
          padding: 0 2em;
          border-left: 1px solid;
          border-right: 1px solid;
          width: 25%;
          text-align: left;

          &:first-child {
            width: 30%;
          }
        }
      }
    }
  }

  .users {
    text-align: left;
  }

  .no-entitlement,
  .no-working-hours {
    color: red;
    font-weight: bolder;
    text-align: left;
  }

  .no-absence-date,
  .loading-data-error {
    padding: 7.5em;
    font-size: calc(1rem + 2px);
  }
}

.dialog-title {
  font-size: large;
  background-color: var(--v-primary-base);
  color: white;
}

.entitlement-data {
  padding-right: 2em;
  padding-left: 2em;
}
</style>
