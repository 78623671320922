<template>
  <v-app id="app">
    <v-navigation-drawer v-model="showDrawer" temporary fixed>
      <v-list
        v-if="
          userMetaData.isAdmin ||
            userMetaData.isManager ||
            userMetaData.isReviewer
        "
      >
        <v-list-tile
          v-for="navigate in navigationPaths"
          :key="navigate.name"
          :to="navigateTo(navigate.path)"
        >
          <v-list-tile-content>
            <v-list-tile-title>
              {{ navigate.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list v-else-if="displayDrawer">
        <v-list-tile
          v-for="navigate in navigationPathsWithoutAdminRights"
          :key="navigate.name"
          :to="navigateTo(navigate.path)"
        >
          <v-list-tile-content>
            <v-list-tile-title>
              {{ navigate.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list v-else>
        <v-list-tile v-on:click="logIn">
          <v-list-tile-content>
            <v-list-tile-title>Login</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      app
      dark
      color="tertiary"
      clipped-left
      height="95px"
      ref="layoutHeader"
    >
      <v-toolbar-side-icon
        v-on:click="showDrawer = !showDrawer"
      >
        <v-icon>menu</v-icon>
      </v-toolbar-side-icon>
      <v-toolbar-title class="pj-title">
        <v-layout row justify-space-around align-center>
          <v-flex shrink v-if="!isOnMobileBrowser()">Cpt. Balance</v-flex>
          <v-flex offset-xs1>
            <breadcrumbs-items></breadcrumbs-items>
          </v-flex>
        </v-layout>
      </v-toolbar-title>
      <v-toolbar-items>
        <div class="logout">
          {{ showCurrentUserFullName() }}
          <v-btn
            icon
            slot="activator"
            @click="logOut"
            v-if="userMetaData.userId"
            class="logout-btn"
          >
            <v-icon>power_settings_new</v-icon>
          </v-btn>
        </div>
        <div class="logo-image" v-if="!isOnMobileBrowser()">
          <v-img
            :src="require('../public/img/icons/FlaviaHeaderLogo.svg')"
            alt=""
            max-width="64"
            height="85"
          />
        </div>
      </v-toolbar-items>
    </v-toolbar>

    <v-content v-if="metaDataFetched || !this.$route.meta.requiresAuth">
      <router-view
        :layoutHeader="($refs['layoutHeader'] || {}).$el"
        :layoutFooter="($refs['layoutFooter'] || {}).$el"
      />
    </v-content>

    <v-snackbar
      v-model="snackbarOptions.show"
      :timeout="snackbarOptions.timeout"
      :color="snackbarOptions.color"
      :multi-line="true"
    >
      {{ snackbarOptions.text }}
      <v-btn dark flat @click="hideSnackbar()">
        {{ snackbarOptions.buttonText }}
      </v-btn>
    </v-snackbar>

    <legend-footer
      v-if="
        $route.name === 'monthlyOverviewRoute' ||
          $route.name === 'yearlyOverviewRoute'
      "
      ref="layoutFooter"
    ></legend-footer>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

import {publicRoutes, restrictedRoutes} from "@/constants";
import {ABSENCE_DATA_ACTIONS, ABSENCE_DATA_GETTERS, AbsenceSpace} from "@/store/modules/absenceData";
import {User, UserMetaData} from "@/models";

import YearlyOverview from "./components/overview/yearlyOverview.vue";
import LegendFooter from "./components/common/legendFooter.vue";
import MonthlyOverview from "./components/overview/monthlyOverview.vue";
import registerServiceWorker from "./registerServiceWorker";
import NotFound from "./components/errors/notFound.vue";
import BreadcrumbsItems from "./components/common/breadcrumbsItems.vue";
import UpdateUserInformation from "./components/updateUserInformation.vue";
import YearlyEntitlement from "./components/entitlement/yearlyEntitlement.vue";
import VacationRequest from "./components/requests/vacationRequest.vue";
import AbsenceRequestsOverview from "./components/requestOverviews/absenceRequestsOverview.vue";
import WorkingHoursOverview from "./components/workingHoursOverview.vue";
import AbsenceOverview from "./components/overview/absenceOverview.vue";

@Component({
  components: {
    yearlyOverview: YearlyOverview,
    monthlyOverview: MonthlyOverview,
    legendFooter: LegendFooter,
    notFound: NotFound,
    updateUserInformation: UpdateUserInformation,
    yearlyEntitlement: YearlyEntitlement,
    breadcrumbsItems: BreadcrumbsItems,
    vacationRequest: VacationRequest,
    absenceRequestsOverview: AbsenceRequestsOverview,
    workingHoursOverview: WorkingHoursOverview,
    absenceOverview: AbsenceOverview
  }
})
export default class App extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUserMetaData)
  fetchUserMetaData!: () => Promise<UserMetaData>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  users!: User[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;

  snackbarOptions = {
    show: false,
    text: "",
    buttonText: "",
    color: "info",
    timeout: 0,
    onClick: (() => undefined) as () => void
  };

  showDrawer = false;
  displayDrawer = true;
  navigationPaths = [...publicRoutes, ...restrictedRoutes];

  navigationPathsWithoutAdminRights = publicRoutes;

  metaDataFetched = false;

  manageUserAccess() {
    const isAccessForbidden =
      !this.userMetaData.isAdmin &&
      !this.userMetaData.isManager &&
      !this.userMetaData.isReviewer &&
      restrictedRoutes.some(
        restrictedRoute =>
          this.$route.path.localeCompare(restrictedRoute.path, undefined, {
            sensitivity: "accent"
          }) === 0
      );

    if (isAccessForbidden) {
      this.$router.push({ name: "forbiddenPage" });
    }

    this.metaDataFetched = true;

    return Promise.resolve();
  }

  fetchMetaData() {
    this.fetchUserMetaData().then(() => this.manageUserAccess());
  }

  mounted() {
    // check if the route needs authentication
    // @ts-ignore
    if (this.$route.meta.requiresAuth) {
      this.fetchMetaData();
    } else {
      // check if the user is logged in to decide what to show in the drawer
      this.axios.get("/security/isLoggedIn").then(response => {
        if (response.data) {
          this.fetchMetaData();
        } else {
          this.displayDrawer = false;
        }
      });
    }

    if (process.env.NODE_ENV === "production") {
      registerServiceWorker();
    }
  }

  showCurrentUserFullName() {
    const {
      userMetaData: { firstName = "", lastName = "" }
    } = this;

    return `${firstName} ${lastName}`;
  }

  logIn() {
    this.$router.push("/");
    location.reload();
  }

  logOut() {
    this.axios
      .get("/security/logout")
      .then(response => (window.location.href = response.data));
  }

  hideSnackbar() {
    if (this.snackbarOptions.onClick) {
      this.snackbarOptions.onClick();
    }
    this.snackbarOptions.show = false;
    this.snackbarOptions.onClick = () => undefined;
    this.snackbarOptions.text = "";
    this.snackbarOptions.buttonText = "";
    this.snackbarOptions.color = "info";
    this.snackbarOptions.timeout = 0;
  }

  // preserve current parameters (selectedUsers) when navigating in the menu
  navigateTo( url: string) {
    const query = this.$route.fullPath.split("?")[1];
    return query ? (url + '?' + query) : url;
  }
}
</script>

<style lang="scss">
$baseRootElementFontSize: 14px;

:root {
  font-size: $baseRootElementFontSize;
  overflow: auto;
}

#loadingSpinner {
  width: 100%;
  height: 80%;
  position: absolute;
  background: url("./assets/loader.gif") center center no-repeat;
}

.theme--light.v-list {
  .v-list__tile--link:hover,
  .v-list__tile--highlighted,
  .v-list__group__header:hover {
    background: var(--v-accent-base) !important;
  }
}

.absence-table {
  th,
  td {
    padding: 0.5rem;
    text-align: center !important;
    border-left: 1px dashed;

    &td {
      white-space: nowrap;
    }

    &th:last-child {
      width: unset;
      height: unset;
    }

    /* leave out border in first column and between weekend days */
    &:first-child,
    &.weekend + .weekend {
      border-left: none;
    }
  }

  tbody tr {
    border-top: none !important;

    &:nth-child(2n) {
      background: var(--v-primary-lighten5) !important;
    }
  }

  &.table-header tr:first-child {
    border-bottom: 2px solid black;
  }
}

.pj-title {
  text-align: left;
}

.v-toolbar {
  div.v-toolbar__content {
    height: auto;
    display: grid;
    grid-template-columns: 55px auto 70px;
    grid-template-rows: auto auto;

    .v-toolbar__items {
      .logo-image {
        position: relative;
        right: 24px;
        top: 34px;
        width: 100%;
        max-width: 65.5px;
        height: 91px;
        padding: 2.5px;
        background: white;
      }

      .logout {
        position: absolute;
        right: 150px;
        top: 24.5px;
      }

      @media (max-width: 750px) {
        .logout {
          position: relative;
          right: 10%;
          top: 13%;
        }
      }
    }
  }
}

.v-content__wrap {
  margin-top: 30px;
}

.v-overlay--active {
  z-index: 95;
}

.v-navigation-drawer.v-navigation-drawer--open.theme--light {
  z-index: 99;
  background-color: var(--v-tertiary-base);

  [role="listitem"] {
    &:hover {
      background-color: var(--v-secondary-base);
    }

    .v-list__tile--active {
      background-color: var(--v-secondary-base);
      border-bottom: 3px solid var(--v-accent-base);
    }

    .v-list__tile__content {
      color: white;
    }
  }
}

.snackbar-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: transparent;
}

.snackbar {
  font-size: large;
  background-color: indianred;
  padding: 0.5rem 0;
  margin: 0 auto;
  width: 50%;
  min-width: 320px;
}

.snackbar-button {
  font-size: inherit;
  padding: 0.2rem 0.5rem;
}

.larger-font {
  font-size: 110%;
}

/* vars for colors are generated by vuetify with option customProperties */
.filter-container {
  position: sticky;
  z-index: 10;
  top: 95px;
  width: 100%;
  background-color: var(--v-primary-lighten5);
  margin-top: -30px;
  padding: 5px 50px 0 50px;
  border-bottom: 2px solid black;

  .filter-input {
    margin-right: 100px;
    padding-bottom: 0.5em;

    .v-input {
      margin-top: 0;
    }
  }
}

.v-icon {
  text-align: center;
}

.user-role-row {
  justify-content: left;
  height: 3em;
  text-align: left;
  width: 100%;
  padding-left: 0.5em;
  padding-top: 0.7em;
  background-color: var(--v-primary-lighten4);
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.table-body > div:first-of-type .user-role-row {
  border-top: none;
}

@media only screen and (max-width: 1366px) {
  :root {
    font-size: calc(#{$baseRootElementFontSize} * 0.85);
  }
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  text-align: center;

  .v-label,
  .v-input,
  .empty-users-warning,
  .v-list__tile {
    font-size: calc(1rem + 2px);
  }

  .v-btn,
  .v-navigation-drawer .v-list__tile {
    font-size: 1rem;
  }

  .v-toolbar {
    z-index: 90;

    .v-toolbar__title {
      font-size: calc(1rem + 6px);
    }
  }

  .breadcrumb,
  .user-role-row {
    font-size: calc(1rem + 4px);
  }
}

.v-tooltip__content {
  z-index: 300 !important;
}
</style>
