<template>
  <div class="working-hours-overview">
    <v-snackbar
      v-model="snackbar"
      :color="transactionError ? 'error' : 'success'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="onCloseSnackbar()"> Schließen</v-btn>
    </v-snackbar>

    <v-layout justify-center row class="users-select-box-wrapper">
      <v-flex>
        <v-spacer />
      </v-flex>
      <v-flex class="users-select-box">
        <v-autocomplete
          :value="selectedUser"
          :type="'User'"
          :disabled="!usersLoaded"
          :loading="!usersLoaded"
          :items="users"
          :item-text="userItemText"
          return-object
          label="Person"
          :no-data-text="getNoDataPlaceholder()"
          required
          hide-selected
          @change="user=>onChangeUser(user)"
        ></v-autocomplete>
      </v-flex>
      <v-flex>
        <v-spacer />
      </v-flex>
    </v-layout>
    <div v-if="userWorkingHoursLoaded" class="table-content-data">
      <div class="working-hours-data-table-wrapper">
        <v-data-table
          :headers="dataTableHeaders"
          :items="userWorkingHours"
          class="working-hours-data-table"
          hide-actions
        >
          <template v-slot:headerCell="props">
            <span>{{ props.header.text }}</span>
          </template>
          <template v-slot:items="props">
            <td>{{ getFormattedDate(props.item.validFrom) }}</td>
            <td>
              {{ formatWorkingHoursInMinutes(props.item.weeklySumInMinutes) }}
            </td>
            <td>
              <v-icon v-if="props.item.hourlyRatedWage">check</v-icon>
              <v-icon v-else>close</v-icon>
            </td>
            <td>
              <v-btn
                icon
                slot="activator"
                class="delete-working-hours-entry"
                @click="() => openEntitlementForm(props.item)"
                :disabled="
                  !userMetaData.isAdmin ||
                    !isLatestEntry(props.item) ||
                    loadingForm
                "
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </div>
      <div v-if="userMetaData.isAdmin" class="add-working-hours-wrapper">
        <h3>Neue Soll-Arbeitszeit eintragen</h3>
        <v-form class="add-working-hours-form" v-model="isFormValid">
          <div class="working-hours-date-picker">
            <template>
              <date-picker
                label="Gültig ab"
                v-model="newWorkingHoursValidFrom"
                :menu-active="showDatePicker"
                :selected-date="newWorkingHoursValidFrom"
                :is-date-allowed="noExistingEntryOnDate"
                @update:menuActive="showDatePicker = $event"
                @update:selectedDate="newWorkingHoursValidFrom = $event"
              />
            </template>
          </div>

          <div class="working-hours-sum-input">
            <v-text-field
              type="string"
              class="working-hours-sum"
              :rules="[
                v => (!!v && v.trim().length > 0) || 'Arbeitszeit erforderlich',
                v =>
                  testWorkingHoursInput(v) ||
                  'Eingabe muss folgendes Format haben: 38h 30m'
              ]"
              label="Arbeitszeit pro Woche"
              v-model="newWorkingHoursString"
            >
            </v-text-field>
          </div>

          <div class="hourly-rated-wage-checkbox">
            <v-checkbox
              type="string"
              class="hourly-rated-wage"
              label="Stundenbasierte Abrechnung"
              v-model="newHourlyRatedWage"
            >
            </v-checkbox>
          </div>

          <div class="add-new-working-hours-button">
            <v-btn
              :disabled="!isFormValid || loadingForm"
              @click="() => openEntitlementForm(null)"
              color="secondary"
              >Arbeitszeit eintragen
            </v-btn>
          </div>
        </v-form>
      </div>

      <entitlement-form
        v-if="selectedUser && shouldShowEntitlementForm"
        :show-form="shouldShowEntitlementForm"
        :show-year-selection="false"
        :submit="submitEntitlementForm"
        :cancel="() => (shouldShowEntitlementForm = false)"
        :initial-data="newEntitlementData"
        :title="
          `Urlaubsanspruch für ${selectedUser.firstName} ${selectedUser.lastName}
          in ${newEntitlementData.year}
          entsprechend der Arbeitszeitänderung anpassen`
        "
        :warning="
          !newEntitlementData.remainingEntitlement
            ? `Für die Person ist in ${newEntitlementData.year}
        noch kein Urlaubsanspruch hinterlegt.
        Um die Arbeitszeit einzutragen muss ein neuer Anspruch angelegt werden.`
            : ``
        "
      ></entitlement-form>
    </div>

    <div v-else>
      <div id="loadingSpinner"></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

import DatePicker from "./common/datePicker.vue";

import {
  DATEPICKER_INPUT_DATE_FORMAT,
  MOBILE_WARNING,
  PLACEHOLDER_NO_SELECTABLE_DATA,
  YEAR_MONTH_DAY_DATEFORMAT
} from "@/constants";
import {User, UserEntitlementData, UserMetaData, WorkingHours} from "@/models";
import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  ABSENCE_DATA_MUTATIONS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {
  formatDateToFormatStringInUTCTz,
  formatWorkingHoursInMinutes,
  getDateContext,
  getUserMessageForErrorReason
} from "@/utils";

import EntitlementForm from "./entitlement/entitlementForm.vue";
import {format} from "date-fns";

@Component({
  components: {
    entitlementForm: EntitlementForm,
    datePicker: DatePicker
  }
})
export default class WorkingHoursOverview extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;

  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  users!: User[];

  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  selectedUsers!: User[];

  selectedUser: User | null = null;
  userWorkingHours: WorkingHours[] = [];
  usersLoaded: boolean = false;
  userWorkingHoursLoaded = false;
  showDatePicker = false;

  shouldShowEntitlementForm = false;
  loadingForm = false;
  newEntitlementData = {} as UserEntitlementData;
  deleteMode = false;

  transactionStatus = "";
  snackbar = false;
  transactionError = false;

  formatWorkingHoursInMinutes = formatWorkingHoursInMinutes;

  dataTableHeaders = [
    {
      text: "Datum",
      value: "date",
      align: "center",
      sortable: false,
      class: "header",
      width: "25%"
    },
    {
      text: "Wochenstunden",
      value: "sum",
      align: "center",
      sortable: false,
      class: "header",
      width: "25%"
    },
    {
      text: "Stundebasierte Abrechnung",
      value: "sum",
      align: "center",
      sortable: false,
      class: "header",
      width: "25%"
    },
    {
      text: "Löschen",
      value: "delete",
      align: "center",
      sortable: false,
      class: "header",
      width: "25%"
    }
  ];

  newWorkingHoursValidFrom = "";
  newWorkingHoursString = "";
  newHourlyRatedWage = false;

  hoursRegex = /[1-9]?[0-9]h/;
  minutesRegex = /[1-5]?[0-9]m/;
  workingHoursInputRegex = /^([1-9]?[0-9]h)?\s?([1-5]?[0-9]m)?$/g;

  isFormValid = false;

  userIdFromRoute = "";

  mounted() {
    this.userIdFromRoute = this.$route.params.userId;
    this.fetchAllUsers().then(() => {
      if (this.userIdFromRoute) {
        this.selectedUser = this.users.find(
          user => user.userId === this.userIdFromRoute
        ) as User;
      } else {
        this.selectedUser =
          this.selectedUsers[0] ||
          (this.users.find(
            user => user.userId === this.userMetaData.userId
          ) as User);
      }
      this.usersLoaded = true;

      this.fetchUserWorkingHours(this.selectedUser.userId);
    });

    if (this.isOnMobileBrowser() && this.userHasWritePermissions()) {
      this.transactionStatus = MOBILE_WARNING;
      this.snackbar = true;
      this.transactionError = true;
    }
  }

  userItemText: (user:User)=>string =
    (user: User) => user.firstName + ", " + user.lastName + " (" + user.userRole + ")";

  getNoDataPlaceholder(): string {
    return PLACEHOLDER_NO_SELECTABLE_DATA;
  }

  getFormattedDate(date: number[]): string {
    return formatDateToFormatStringInUTCTz(
      getDateContext(date),
      DATEPICKER_INPUT_DATE_FORMAT
    );
  }

  convertInputStringToMinutes(workingHoursString: string): number {
    let workingHoursInMinutes = 0;

    const hoursMatch = this.hoursRegex.exec(workingHoursString);
    if (hoursMatch) {
      workingHoursInMinutes += Number(hoursMatch[0].slice(0, -1)) * 60;
    }

    const minutesMatch = this.minutesRegex.exec(workingHoursString);
    if (minutesMatch) {
      workingHoursInMinutes += Number(minutesMatch[0].slice(0, -1));
    }

    return workingHoursInMinutes;
  }

  formatSelectedDateOutput(date: string) {
    if (!date) {
      return "";
    }
    return formatDateToFormatStringInUTCTz(
      getDateContext(date),
      DATEPICKER_INPUT_DATE_FORMAT
    );
  }

  openEntitlementForm(entryToDelete: WorkingHours | null) {
    if (this.selectedUser) {
      this.deleteMode = !!entryToDelete;
      this.loadingForm = true;
      const year = getDateContext(
        entryToDelete ? entryToDelete.validFrom : this.newWorkingHoursValidFrom
      ).getFullYear();

      // set it here, so it is set even if getUserEntitlement fails
      this.newEntitlementData.userId = this.selectedUser.userId;
      this.newEntitlementData.year = year;

      this.axios
        .get(`/api/getUserEntitlement/${this.selectedUser.userId}/${year}`)
        .then(
          entitlement =>
            (this.newEntitlementData = {
              ...entitlement.data,
              remark: ""
            } as UserEntitlementData)
        )
        .catch(error => {
          this.transactionStatus = getUserMessageForErrorReason(error);
          this.snackbar = true;
          this.transactionError = true;
        })
        .finally(() => {
          this.shouldShowEntitlementForm = true;
          this.loadingForm = false;
        });
    }
  }

  onCloseSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  // TODO: create consistency between EntitlementData and UserEntitlementData
  submitEntitlementForm(entitlementData: UserEntitlementData) {
    let entryToSubmit;
    if (this.deleteMode) {
      entryToSubmit = {
        affectedEmployee: entitlementData.userId,
        ...entitlementData
      };
    } else {
      entryToSubmit = {
        userId: entitlementData.userId,
        validFrom: format(
          getDateContext(this.newWorkingHoursValidFrom),
          YEAR_MONTH_DAY_DATEFORMAT
        ),
        weeklySumInMinutes: this.convertInputStringToMinutes(
          this.newWorkingHoursString
        ),
        hourlyRatedWage: this.newHourlyRatedWage,
        remainingEntitlement: entitlementData.remainingEntitlement,
        yearlyEntitlement: entitlementData.yearlyEntitlement,
        remark: entitlementData.remark
      };
    }

    this.axios
      .post(
        this.deleteMode
          ? `/api/deleteLatestWorkingHoursEntry`
          : `/api/addUserWorkingHours`,
        entryToSubmit
      )
      .then(() => {
        this.fetchUserWorkingHours(entitlementData.userId);
      })
      .finally(() => {
        this.shouldShowEntitlementForm = false;
        this.newWorkingHoursValidFrom = "";
        this.newWorkingHoursString = "";
        this.newHourlyRatedWage = false;
      });
  }

  isLatestEntry(entry: WorkingHours) {
    return entry === this.getLatestEntry();
  }

  noExistingEntryOnDate = (dateString: string) => {
    const formattedDate = this.formatSelectedDateOutput(dateString);
    return !this.userWorkingHours.find(
      entry => this.getFormattedDate(entry.validFrom) === formattedDate
    );
  }

  testWorkingHoursInput(inputString: string) {
    this.workingHoursInputRegex.lastIndex = 0;
    return this.workingHoursInputRegex.test(inputString);
  }

  getLatestEntry() {
    return this.userWorkingHours[this.userWorkingHours.length - 1];
  }

  onChangeUser(user: User) {
    this.selectedUser = user;
    this.fetchUserWorkingHours(this.selectedUser.userId);
  }

  fetchUserWorkingHours(userId: string) {
    this.userWorkingHoursLoaded = false;

    this.axios.get(`/api/getUserWorkingHours/${userId}`).then(response => {
      this.userWorkingHours = response.data as WorkingHours[];
      this.userWorkingHoursLoaded = true;
    });
  }

  userHasWritePermissions(): boolean {
    return this.userMetaData.isAdmin || this.userMetaData.isManager;
  }
}
</script>

<style lang="scss">
.add-working-hours-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 1% 1% 1%;
  background: var(--v-primary-lighten5);

  h3 {
    text-align: left;
    padding: 10px 0;
  }
}

.add-working-hours-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 auto;
  border-top: 1px solid var(--v-primary-lighten2);
  border-bottom: 1px solid var(--v-primary-lighten2);

  > div {
    border-right: 1px solid var(--v-primary-lighten2);
    background: whitesmoke;

    > * {
      padding: 0 10px;
    }

    &:first-of-type {
      border-left: 1px solid var(--v-primary-lighten2);
    }
  }

  .working-hours-date-picker,
  .working-hours-sum-input {
    width: 40%;
  }

  .add-new-working-hours-button {
    width: 20%;
    padding: 10px;

    button {
      margin: 0;
      padding: 12px;
      width: 100%;
      box-sizing: border-box;

      div {
        width: 100%;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
  }
}

.working-hours-data-table-wrapper {
  margin: 2.5% 7.5%;

  .working-hours-data-table {
    .v-datatable {
      thead {
        tr {
          border: 1px solid var(--v-primary-lighten2);
          border-bottom: unset;

          th {
            border-left: 1px solid var(--v-primary-lighten2);
            background: var(--v-primary-lighten5);

            &:first-of-type {
              &:hover {
                background: var(--v-primary-lighten3);
              }
            }
          }
        }
      }

      tbody {
        tr {
          border: 1px solid var(--v-primary-lighten2);
          border-top: unset;

          td {
            border-left: 1px solid var(--v-primary-lighten2);
          }
        }
      }
    }
  }
}
</style>
