<template>
  <div>
    <v-flex>
      <v-layout row>
        <v-autocomplete
          v-model="selectedUserIds"
          :search-input.sync="searchText"
          :placeholder="placeholder"
          multiple
          label="Filter:"
          :items="allValidUsers"
          item-value="userId"
          :filter="customFilter"
          :disabled="!usersLoaded || disableInput"
          :loading="!usersLoaded"
          @change="applyChange"
          :no-data-text="getNoDataPlaceholder()"
          autofocus
          auto-select-first
          hide-selected
          hide-details
          clearable
          :menu-props="menuProps"
        >
          <template v-slot:selection="data">
            <v-chip
              v-if="allUsersSelected() && data.index === 0"
              close
              class="chip--select-multi"
              @input="toggleSelectAll()"
            >
              Alle ({{ selectedUserIds.length }})
            </v-chip>
            <v-chip
              v-else-if="!allUsersSelected() && (showMore || data.index < 10)"
              :selected="data.selected"
              close
              class="chip--select-multi"
              @input="remove(data.item)"
            >
              {{ data.item.firstName }}
              {{ data.item.lastName }}
            </v-chip>
            <v-chip
              v-else-if="!allUsersSelected() && data.index == 10"
              @click="showMore = true"
              class="chip--select-multi"
            >
              {{ selectedUserIds.length - 10 }} weitere..
            </v-chip>
          </template>
          <template v-slot:prepend-inner >
            <v-list-tile ripple @click="toggleSelectAll()">
              <v-btn v-if="allUsersSelected()">Einzelne Personen auswählen..</v-btn>
              <v-btn v-else>Alle Personen auswählen</v-btn>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:item="data">
            <img v-if="data.item.ldapName" onerror="this.src=''"
                 :src="`https://selfcare.flavia-it.de/user/image/${data.item.ldapName}?size=32&no404=true&noCache=false`"
                 alt="userimage"/>
              {{ data.item.firstName }} {{ data.item.lastName }} ({{data.item.userRole}})
          </template>
          <template v-slot:append-item v-if="!allUsersSelected()">
            <span v-if="filteredGroups().length">
              Gruppen
              <v-divider class="mt-2"></v-divider>
            </span>
            <v-list-tile v-for="group in filteredGroups()" :key="group.name" v-ripple @click="selectAllByGroup(group, false)">
              <span>{{group.name}} ({{group.size}})</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, appendButton }">
                  <v-btn
                    icon
                    v-bind="appendButton"
                    v-on="on"
                    v-ripple.stop
                    v-on:click.stop
                    @click="selectAllByGroup(group, true)"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Alle Mitglieder der Gruppe zur Auswahl hinzufügen</span>
              </v-tooltip>
            </v-list-tile>
          </template>
        </v-autocomplete>
      </v-layout>
    </v-flex>
    <div
      v-if="usersLoaded && invalidUsers && invalidUsers.length > 0 && !allUsersSelected()"
      class="empty-users-warning"
    >
      <div>
        Für folgende Personen wurde für das ausgewählte Jahr noch kein
        Abwesenheits-Status bzw. Sollarbeitszeit eingetragen:
      </div>
      <span
        v-for="(user, index) in invalidUsers"
        :key="user.userId"
      >
        {{ user.firstName }} {{ user.lastName
        }}{{ index !== invalidUsers.length - 1 ? "," : "" }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";
import {Prop, Watch} from "vue-property-decorator";

import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  ABSENCE_DATA_MUTATIONS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {PLACEHOLDER_NO_SELECTABLE_DATA} from "@/constants";
import {
  Absences,
  AbsencesInMonthlyOverview,
  AbsencesInYearlyOverview,
  User,
  UserMetaData,
  UserRequestAccuracy
} from "@/models";

interface Group {
  name: string;
  size: number;
}

@Component
export default class UserFilter extends Vue {
  @Prop(Function) updateUserSelection!: () => void;
  @Prop({ default: false }) contentIsLoaded!: boolean;
  @Prop({ default: "Person(en) auswählen" })
  readonly placeholder!: string;
  @Prop({ default: "" }) overviewType!: string;
  @Prop({ default: false }) disableInput!: boolean;

  @Prop({ default: { year: new Date().getFullYear(), month: null } })
  userRequestAccuracy!: UserRequestAccuracy;

  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllAbsencesInMonth)
  fetchedMonthlyAbsencesData!: Absences<AbsencesInMonthlyOverview>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllAbsencesInYear)
  fetchedYearlyAbsencesData!: Absences<AbsencesInYearlyOverview>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllValidUsers)
  allValidUsers!: User[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllValidUsers)
  fetchAllValidUsers!: (
    userRequestAccuracy: UserRequestAccuracy
  ) => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];
  @AbsenceSpace.Mutation(ABSENCE_DATA_MUTATIONS.setSelectedUsers)
  setSelectedUsers!: (selectedUsers: User[]) => void;

  // props given to underlying v-menu, so we have better control of visibility
  menuProps = { closeOnContentClick:false, openOnFocus: true, openOnHover: true, maxHeight:700 };

  // invalid Users are those selected before, but not in validUsers (e.g. no entry for this year)
  invalidUsers: User[] = [];
  selectedUserIds: string[] = [];
  searchText = "";
  usersLoaded = false;
  showMore = false;

  private readonly ALL_USERS_QUERY = "all";

  // cache group information
  private allGroups: Group[] = [];

  @Watch("$route.params.year")
  @Watch("$route.params.month")
  onParamsChange() {
    const userRequestAccuracy: UserRequestAccuracy = Object.assign(
      {},
      this.userRequestAccuracy
    );
    this.internalFetchAllValidUsers(userRequestAccuracy);
  }

  beforeMount() {
    const userRequestAccuracy: UserRequestAccuracy = Object.assign(
      {},
      this.userRequestAccuracy
    );
    this.internalFetchAllValidUsers(userRequestAccuracy);
  }

  customFilter(item: User, queryText: string): boolean {
    const searchTerm = queryText.toLowerCase();
    const {firstName, lastName, email} = item;
    return (
      (!"flavia".includes(searchTerm) && email.toLowerCase().includes(searchTerm)) ||
      `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(
        searchTerm
      )
    );
  }

  remove(item: User) {
    if (this.allUsersSelected()) {
      // remove "All" Options => no user selected
      this.selectedUserIds = [];
    }
    this.selectedUserIds = this.selectedUserIds
      .filter(userID => userID !== item.userId);

    this.selectFromValidUsersAndUpdateUrl();
  }

  applyChange() {
    this.searchText = "";
    this.showMore = false;
    this.selectFromValidUsersAndUpdateUrl();
  }

  selectUsersByUrlParameters() {
    const urlSelectedUsersIds = this.$route.query.selectedUser;

    // Query for ALL users.
    if (urlSelectedUsersIds == this.ALL_USERS_QUERY
    ) {
      // just select all valid Users
      this.selectedUserIds = this.allValidUsers.map( u=>u.userId );
    } else
      // Some Users: select them
    if (Array.isArray(urlSelectedUsersIds)) {
      this.selectedUserIds = urlSelectedUsersIds as Array<string>;
    } else if (urlSelectedUsersIds) {
      this.selectedUserIds = [urlSelectedUsersIds];
    } else {
      // No User selected: Take current User (non-admin mode)
      const currentUser = this.allValidUsers.find(
        user => user.userId === this.userMetaData.userId
      );
      this.selectedUserIds = currentUser ? [currentUser.userId] : [];
    }

    // determine invalid Users from previous selection
    this.invalidUsers = this.getSelectedUsers.filter( u=> !this.allValidUsers.map(v=>v.userId).includes(u.userId) );
    // we have to add them to the selection, else they would be lost
    this.setSelectedUsers( this.filterByIdsFromAllValidUsers().concat(this.invalidUsers));
  }

  private filterByIdsFromAllValidUsers(): User[] {
    return this.allValidUsers.filter(user => {
      return this.selectedUserIds.includes(user.userId);
    });
  }

  // take the currently selected Users (this.selectedUserIds) and build Url Param selectedUser from it with special case 'all'
  updateUrlParamsToSelectedUsers() {
    const query = this.allUsersSelected() ? this.ALL_USERS_QUERY : this.selectedUserIds;
    const newUrl = this.$router.resolve({
      query: {
        selectedUser: query
      }
    });

    if (this.$router.currentRoute.fullPath !== newUrl.href) {
      this.$router.replace({
        path: this.$router.currentRoute.path,
        query: newUrl.resolved.query
      });
    }

    // propagate selection to other components
    this.updateUserSelection();
  }

  // fetches all Users that are valid in the selected time span (have non-zero working hours)
  internalFetchAllValidUsers(userRequestAccuracy: UserRequestAccuracy) {
    this.usersLoaded = false;
    this.fetchAllValidUsers(userRequestAccuracy).then(() => {
      this.setAllUsersAndGroups();
      // we ignore the param, users are now in this.allValidUsers (TODO: we really want this side effect programming?)
      this.usersLoaded = true;
      // no we can analyse the url params
      this.selectUsersByUrlParameters();
    });
  }

  getNoDataPlaceholder() {
    return PLACEHOLDER_NO_SELECTABLE_DATA;
  }

  allUsersSelected(): boolean {
    if (this.selectedUserIds.length !== this.allValidUsers.length) {
      return false;
    }
    return this.selectedUserIds.every((userID: string) =>
      this.allValidUsers.map((validUser: User) => validUser.userId).includes(userID)
    );
  }

  toggleSelectAll() {
    if (this.allUsersSelected()) {
      this.selectedUserIds = [];
    } else {
      this.selectedUserIds = this.allValidUsers.map(u=>u.userId);
      this.invalidUsers = [];
      this.selectFromValidUsersAndUpdateUrl();
    }
  }

  setAllUsersAndGroups() {
    const groupNames = Array.from(new Set(this.allValidUsers.filter(u=> u.groups).flatMap(u=>u.groups).sort() as string[]));
    this.allGroups = groupNames.map(g => (
      {
        name: g,
        size: this.allValidUsers.filter(u=> u.groups && u.groups.includes(g)).length
      }) as Group);

    // manager should only see developer groups
    if (this.userMetaData.isManager && !this.userMetaData.isAdmin) {
      this.allGroups = this.allGroups.filter(g=>g.name.includes("developer"));
    }
  }

  filteredGroups(): Group[] {
    return this.allGroups.filter(g => !this.searchText || g.name.includes(this.searchText));
  }

  selectAllByGroup(group: Group, append: boolean) {
    const usersInGroup = this.allValidUsers.filter(u=> u.groups && u.groups.includes(group.name)).map(u=>u.userId);
    if (append) {
      this.selectedUserIds = this.selectedUserIds.concat(usersInGroup.filter(uId => !this.selectedUserIds.includes(uId)));
    } else {
      this.selectedUserIds = usersInGroup;
    }
    this.searchText = "";
    this.selectFromValidUsersAndUpdateUrl();
  }

  private selectFromValidUsersAndUpdateUrl() {
    this.setSelectedUsers(this.filterByIdsFromAllValidUsers());
    this.updateUrlParamsToSelectedUsers();
  }
}
</script>

<style lang="scss">

.white-caret {
  caret-color: whitesmoke !important;
}

.empty-users-warning {
  color: var(--v-error-base);
  font-weight: bold;
  padding: 5px 0;

  > div {
    padding: 0 0.2em;
  }
}
</style>
