<template>
  <div>
    <div v-if="userHasReadRights" class="filter-container" ref="userFilter">
      <user-filter
        class="filter-input"
        :updateUserSelection="()=>{}"
        :contentIsLoaded="requestHandled"
        :user-request-accuracy="{
          year: parseInt($route.params.year),
          month: parseInt($route.params.month)
        }"
        overview-type="monthly"
        :disableInput="isDataTableEditMode"
      ></user-filter>
      <div class="toolbar">
        <div
          v-if="changeData && changeData.length"
          class="remaining-entitlement-wrapper"
        >
          <div>Resturlaub nach den Änderungen</div>
          <div class="changed-remaining-entitlement">
            <div>
              <div>Name:</div>
              <v-divider />
              <div>Resturlaub {{ year - 1 }}:</div>
              <v-divider />
              <div>Resturlaub {{ year }}:</div>
            </div>
            <div v-for="change in changeData">
              <div>
                {{ change.user.firstName + " " + change.user.lastName }}
              </div>
              <v-divider />
              <div>
                {{
                  change.remainingPreviousYear.toFixed(2) +
                    formatDifference(change.differencePreviousYear)
                }}
              </div>
              <v-divider />
              <div>
                {{
                  change.remainingEntitlement.toFixed(2) +
                    formatDifference(change.differenceCurrentYear)
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="edit-mode-buttons" v-if="userMetaData.isAdmin">
          <v-btn
            icon
            @click="
              () =>
                isDataTableEditMode ? saveData() : onToggleDataTableEditMode()
            "
            :disabled="shouldDisableButtons"
          >
            <v-icon class="save-absences-btn" v-if="isDataTableEditMode"
              >done
            </v-icon>
            <v-icon class="change-absences-btn" v-if="!isDataTableEditMode"
              >edit
            </v-icon>
          </v-btn>

          <v-btn
            icon
            color="error"
            v-if="isDataTableEditMode"
            @click="discardChanges"
            class="cancel-changes"
            :disabled="shouldDisableButtons"
          >
            <v-icon light>close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="monthly-overview-wrapper" ref="tableWrapper">
      <div class="monthly-overview-layout">
        <v-snackbar
          v-model="snackbar"
          :color="transactionError ? 'error' : 'success'"
          top
        >
          {{ transactionStatus }}
          <v-btn color="white" flat @click="closeSnackbar()">Schließen</v-btn>
        </v-snackbar>

        <div
          v-if="fetchedAbsencesData && !shouldHideDataTable"
          class="yearly-overview-layout"
          :class="{
            'overflowed-layout-view': userHasReadRights,
            'is-edit-mode': isDataTableEditMode
          }"
        >
          <v-data-table
            :headers="monthHeaders"
            class="absence-table text-sm-center table-header"
            :items="[]"
            hide-actions
            hide-default-footer
            ref="tableHeader"
          >
            <template v-slot:no-data>
              <tr style="visibility: hidden"></tr>
            </template>
            <template v-slot:headerCell="props">
              <v-layout column>
                <v-flex>
                  <div>{{ props.header.text.split(" ")[0] }}</div>
                  <div>{{ props.header.text.split(" ")[1] }}</div>
                </v-flex>
                <v-flex
                  v-if="
                    isDataTableEditMode &&
                      props.header.class !== 'weekend' &&
                      props.header.text !== 'Name' &&
                      props.header.text !== 'Kommentar'
                  "
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> arrow_drop_down_circle</v-icon>
                    </template>
                    <v-list>
                      <v-list-tile @click="markAsHoliday(props.header.value)">
                        <v-list-tile-title>
                          Diesen Tag als Feiertag für alle ausgewählten Personen
                          eintragen
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-layout>
            </template>
          </v-data-table>
          <div class="table-body" v-if="requestHandled" ref="tableBody">
            <div
              v-for="role in userRolesSort"
              :key="role"
              :value="role"
              :id="role"
            >
              <div v-if="fetchedAbsencesData[role]">
                <div v-if="userHasReadRights" class="user-role-row">
                  {{ userRoles[role] }}
                </div>

                <!-- shouldn't hide actions if that role has more than 30 entries in given month, the other two checks
        prevent NullPointerExceptions-->
                <v-data-table
                  hide-headers
                  :items="
                    isDataTableEditMode
                      ? editedAbsencesData[role]
                      : fetchedAbsencesData[role]
                  "
                  class="absence-table"
                  hide-actions
                  hide-default-footer
                >
                  <template v-slot:items="props">
                    <td class="user-name-month">
                      {{ props.item.user.lastName }},
                      {{ props.item.user.firstName }}
                    </td>
                    <td
                      :title="
                        isPlannedVacation(index, props.item.plannedAbsences) &&
                        !isWeekend(index)
                          ? 'An diesem Tag wurde Urlaub angefragt'
                          : ''
                      "
                      class="absences-in-day-monthly"
                      :class="{
                        weekend: isWeekend(index),
                        'planned-absence':
                          isPlannedVacation(
                            index,
                            props.item.plannedAbsences
                          ) && !isWeekend(index)
                      }"
                      v-for="(absence, index) in props.item.absences"
                      :key="index"
                    >
                      <v-select
                        v-if="!isWeekend(index) && userMetaData.isAdmin"
                        v-show="isDataTableEditMode"
                        class="absence-type-select"
                        :items="vacationTypeOptions"
                        item-value="abbr"
                        @change="onDetectChanges"
                        v-model="props.item.absences[index]"
                      >
                        <template v-slot:selection="{ item }">
                          {{ item.abbr }}
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.state }}
                        </template>
                      </v-select>
                      <div
                        v-if="!isWeekend(index)"
                        v-show="!isDataTableEditMode"
                      >
                        {{ absence }}
                      </div>
                    </td>
                    <td
                      v-if="userMetaData.isAdmin"
                      v-show="isDataTableEditMode"
                      class="remark-month"
                    >
                      <v-text-field
                        class="remark-to-add"
                        maxlength="512"
                        v-model="remarks[props.item.user.userId]"
                        hide-details
                      >
                      </v-text-field>
                    </td>
                    <div
                      v-if="userMetaData.isAdmin"
                      v-show="isDataTableEditMode"
                    ></div>
                  </template>
                  <template v-slot:no-data>
                    <tr style="visibility: hidden"></tr>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!requestHandled || !fetchedAbsencesData || shouldHideDataTable">
          <div
            id="loadingSpinner"
            v-if="!requestHandled"
          ></div>
          <div class="no-absence-date" v-else-if="!errorLoadingData">
            <strong> Es gibt keine Einträge in diesem Monat </strong>
          </div>
          <div v-else class="loading-data-error">
            <strong>
              Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
              {{ errorLoadingData }}
            </strong>
          </div>
        </div>
      </div>
      <v-dialog v-model="shouldShowDialog" width="500">
        <v-card>
          <v-card-title class="headline">Bestätigen</v-card-title>

          <v-card-text>
            Diese Seite enthält ungespeicherte Änderungen! Diese gehen bei
            Verlassen der Seite verloren.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="leavePageWithoutSaving">
              Seite verlassen
            </v-btn>
            <v-btn color="primary" text @click="stayOnCurrentPage"
              >Abbruch</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { AxiosPromise } from "axios";
import { startOfDay } from "date-fns";

import UserFilter from "../common/userFilter.vue";
import {
  MOBILE_WARNING,
  userRolesByKey,
  VACATION_TYPES,
  YEAR_MONTH_DAY_DATEFORMAT
} from "@/constants";
import {
  AbsenceRequest,
  Absences,
  AbsencesInMonthlyOverview,
  ChangeData,
  User,
  UserMetaData,
  UserRequestAccuracy
} from "@/models";
import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {
  formatDateToFormatStringInUTCTz,
  getUserMessageForErrorReason,
  getVacationAbbrByType,
  hasReadRights
} from "@/utils";

interface UserRemark {
  [key: string]: string;
}

interface AbsenceType {
  date: string;
  type: string;
}

interface AbsencesToUpdate {
  affectedEmployee: string;
  data: AbsenceType[];
  startOfRange: string;
  endOfRange: string;
  remark: string;
}

@Component({ components: { userFilter: UserFilter } })
export default class MonthlyOverview extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllAbsencesInMonth)
  fetchedAbsencesData!: Absences<AbsencesInMonthlyOverview>;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAbsencesOfMonth)
  fetchAbsencesOfMonth!: ([year, month, users]: [
    number,
    number,
    string[]
  ]) => Promise<Absences<AbsencesInMonthlyOverview>>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllValidUsers)
  allValidUsers!: User[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllValidUsers)
  fetchAllValidUsers!: (
    userRequestAccuracy: UserRequestAccuracy
  ) => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUserMetaData)
  fetchUserMetaData!: () => Promise<UserMetaData>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];

  editedAbsencesData: Absences<AbsencesInMonthlyOverview> = {};

  transactionStatus = "";
  snackbar = false;
  transactionError = false;
  userRoles = { ...userRolesByKey };
  userRolesSort = Object.getOwnPropertyNames(this.userRoles);
  requestHandled = false;
  errorLoadingData = null;
  weekendDays: number[] = [];
  isDataTableEditMode = false;
  shouldDisableButtons = false;
  shouldHideDataTable = false;
  vacationTypeOptions = [
    { state: "", abbr: "" },
    ...VACATION_TYPES.map(vacationType => {
      return {
        state: vacationType,
        abbr: getVacationAbbrByType(vacationType)
      };
    }).sort()
  ];
  remarks: UserRemark = {};
  year: number = 1970;
  month: number = 0;

  shouldShowDialog = false;
  nextPageRoute = {} as Route;

  monthHeaders = [
    {
      text: "Name",
      value: "name",
      sortable: true,
      class: ""
    }
  ];
  changeData: ChangeData[] = [];

  @Watch("$route")
  updateMonthData() {
    this.weekendDays = [];
    this.monthHeaders = [
      {
        text: "Name",
        value: "name",
        sortable: false,
        class: "user-name-month"
      }
    ];
    this.year = parseInt(this.$route.params.year, 10);
    this.month = parseInt(this.$route.params.month, 10);
    for (let i = 1; i <= this.daysInMonth; i++) {
      const currentDay = this.getFormattedMonthDay(i);
      const isWeekend = currentDay.includes("So") || currentDay.includes("Sa");
      let columnClass = "absences-in-day-monthly";
      if (isWeekend) {
        this.weekendDays.push(i);
        columnClass = "weekend";
      } else {
        this.weekendDays.push(0);
      }
      this.monthHeaders.push({
        text: currentDay,
        value: i.toString(10),
        sortable: false,
        class: columnClass
      });
    }
    if (this.isDataTableEditMode) {
      this.monthHeaders.push({
        text: "Kommentar",
        value: "comment",
        sortable: false,
        class: "remark-month"
      });
    }
    this.loadData();
  }

  created() {
    window.addEventListener("resize", this.onResize);
  }

  mounted() {
    new Promise(resolve => {
      if (!!this.userMetaData.userId) {
        this.fetchUserMetaData()
          .catch(error => {
            this.transactionStatus = getUserMessageForErrorReason(error);
            this.transactionError = true;
            this.snackbar = true;
          })
          .finally(() => {
            resolve(null);
          });
      } else {
        resolve(null);
      }
    }).then(() => {
      this.updateMonthData();
    });

    if (this.isOnMobileBrowser() && this.userHasWritePermissions()) {
      this.transactionStatus = MOBILE_WARNING;
      this.snackbar = true;
      this.transactionError = true;
    }
  }

  updated() {
    this.onResize();
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  loadData() {
    let usersToLoad: string[];
    this.requestHandled = false;
    if (
      this.getSelectedUsers.length > 0 &&
      (this.userMetaData.isAdmin ||
        this.userMetaData.isManager ||
        this.userMetaData.isReviewer)
    ) {
      usersToLoad = this.getSelectedUsers.map(user => user.userId);
    } else {
      usersToLoad = [this.userMetaData.userId];
    }
    this.fetchAbsencesOfMonth([
      parseInt(this.$route.params.year, 10),
      parseInt(this.$route.params.month, 10),
      usersToLoad
    ])
      .then(this.setInitialEditableAbsencesData)
      .catch(err => (this.errorLoadingData = err))
      .finally(() => (this.requestHandled = true));
  }

  onResize() {
    // Small delay to ensure correct content height recalculation when resizing screen
    const timeout = setTimeout(() => {
      this.adjustTableLayout();
      clearTimeout(timeout);
    }, 300);
  }

  adjustTableLayout() {
    if (this.userHasReadRights) {
      const tableHeader = (
        (this.$refs.tableHeader as Record<string, any>) || {}
      ).$el as HTMLElement;

      const tableBody = this.$refs.tableBody as HTMLElement;

      if (tableHeader && tableBody) {
        const layoutHeader = (this.$attrs as Record<string, any>)
          .layoutHeader as HTMLElement;
        const layoutFooter = (this.$attrs as Record<string, any>)
          .layoutFooter as HTMLElement;
        const userFilter = this.$refs.userFilter as HTMLElement;
        const tableWrapper = this.$refs.tableWrapper as HTMLElement;

        // Check if the table wrapper has a horizontal scrollbar
        const isOverflowingWidth =
          tableWrapper.clientWidth < tableWrapper.scrollWidth;

        // Check if the table body has a vertical scrollbar
        const isOverflowingHeight =
          tableBody.clientHeight < tableBody.scrollHeight;

        // In case of horizontal scrollbar on the table wrapper:
        // Calculating scrollbar space in order to exclude it by table body height calculation
        const horizontalScrollSpace = isOverflowingWidth
          ? tableWrapper.offsetHeight - tableWrapper.clientHeight
          : 0;

        // Calculating table body height dynamically (full view port height excluding everything except of table body)
        tableBody.style.height = `calc(100vh - ${tableHeader.offsetHeight +
        userFilter.offsetHeight +
        layoutHeader.offsetHeight +
        layoutFooter.offsetHeight}px - ${horizontalScrollSpace}px)`;

        // In case of vertical scrollbar on the table body:
        // Vertical scrollbar takes some extra space, it breaks the table layout (columns of the table header and footer are misaligned in relation to each other)
        // In this case scroll bar space on the table body will calculated and applied as margin-left to the table header
        if (isOverflowingHeight) {
          const verticalScrollSpace =
            tableBody.offsetWidth - tableBody.clientWidth;

          tableHeader.style.marginRight = `${verticalScrollSpace}px`;
        } else {
          tableHeader.style.marginRight = `0px`;
        }
      }
    }
  }

  // Note that java- and typescript months start from 0 so this.$route.params.month describes the next month
  // However, due to the third parameter being 0, the last day of the "previous" month is selected so these two "errors"
  // cancel each other out and we get the current month's amount of days
  get daysInMonth() {
    return new Date(this.year, this.month, 0).getDate();
  }

  isWeekend(index: number) {
    if (this.weekendDays[index]) {
      return true;
    }
  }

  isPlannedVacation(index: number, plannedAbsences: AbsenceRequest[]) {
    const checkDate = new Date(this.year, this.month - 1, index + 1);

    for (const plannedAbsence of plannedAbsences) {
      const startDate = startOfDay(new Date(plannedAbsence.startDate));
      const endDate = startOfDay(new Date(plannedAbsence.endDate));

      if (checkDate >= startDate && checkDate <= endDate) {
        return true;
      }
    }

    return false;
  }

  createRemarksForUsers() {
    if (this.fetchedAbsencesData) {
      const userRemarksData: UserRemark = {};
      for (const userRole in this.userRoles) {
        if (this.fetchedAbsencesData[userRole]) {
          this.fetchedAbsencesData[userRole].forEach(
            (userData: AbsencesInMonthlyOverview) => {
              userRemarksData[userData.user.userId] = "";
            }
          );
        }
      }
      this.remarks = userRemarksData;
    }
  }

  // TODO: instead of debouncing by a fixed amount of time, it would be better to wait for the data to be loaded
  // timeout can be very short for hideDataTable since the loading spinner is only replaced once the data table is loaded
  debounceAction(
    property: "shouldDisableButtons" | "shouldHideDataTable",
    time: number = 10
  ) {
    let timeOut: number;

    this[property] = true;

    new Promise(() => {
      timeOut = setTimeout(() => (this[property] = false), time);
    }).finally(() => {
      clearTimeout(timeOut);
    });
  }

  onToggleDataTableEditMode() {
    this.isDataTableEditMode = !this.isDataTableEditMode;

    this.debounceAction("shouldDisableButtons", 1000);
    this.debounceAction("shouldHideDataTable");

    if (this.isDataTableEditMode) {
      this.monthHeaders.push({
        text: "Kommentar",
        value: "comment",
        sortable: false,
        class: "remark-month"
      });
    } else {
      this.monthHeaders = this.monthHeaders.filter(e => e.value !== "comment");
    }

    this.setInitialEditableAbsencesData();
  }

  stayOnCurrentPage() {
    this.shouldShowDialog = false;
    this.nextPageRoute = {} as Route;
  }

  leavePageWithoutSaving() {
    const {
      nextPageRoute: { path }
    } = this;

    this.setInitialEditableAbsencesData();
    this.$router.push(path);
  }

  hasUnsavedChanges() {
    const { isDataTableEditMode } = this;

    return !!(isDataTableEditMode && this.changeData.length);
  }

  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (this.hasUnsavedChanges()) {
      this.shouldShowDialog = true;
      this.nextPageRoute = to;
    } else {
      next();
    }
  }

  beforeRouteUpdate(to: Route, from: Route, next: any) {
    if (this.isDataTableEditMode && this.hasUnsavedChanges()) {
      if (confirm("Die Eingaben werden gelöscht. Bestätigen?")) {
        this.debounceAction("shouldHideDataTable");
        next();
        this.remarks = {};
      }
    } else {
      next();
    }
  }

  setInitialEditableAbsencesData() {
    this.changeData = [];

    if (this.fetchedAbsencesData) {
      this.editedAbsencesData = {};
      for (const userRole in this.userRoles) {
        if (this.fetchedAbsencesData[userRole]) {
          this.editedAbsencesData[userRole] = this.fetchedAbsencesData[
            userRole
            ].map((userAbsence: AbsencesInMonthlyOverview) => {
            return this.mapFetchedDataToChangeData(userAbsence, 0);
          });
        }
      }
      this.createRemarksForUsers();
    }
  }

  absencesChanged(oldData: string[], newData: string[]) {
    return !oldData.every((val, index) => val === newData[index]);
  }

  entitlementValue(absences: string[]) {
    return absences.reduce((previousValue: number, currentValue: string) => {
      if (currentValue === "U") {
        return previousValue + 1;
      } else if (currentValue === "u") {
        return previousValue + 0.5;
      } else {
        return previousValue;
      }
    }, 0);
  }

  mapFetchedDataToChangeData(
    data: AbsencesInMonthlyOverview,
    differenceCurrentYear: number
  ): ChangeData {
    if (differenceCurrentYear < 0) {
      const spaceLeft = data.yearlyEntitlement - data.remainingEntitlement;
      const deltaCurrent = Math.max(-spaceLeft, differenceCurrentYear);

      return {
        user: { ...data.user },
        month: data.month,
        absences: [...data.absences],
        year: data.year,
        plannedAbsences: data.plannedAbsences,
        remainingEntitlement: data.remainingEntitlement - deltaCurrent,
        differenceCurrentYear: deltaCurrent,
        remainingPreviousYear:
          data.remainingPreviousYear - (differenceCurrentYear - deltaCurrent),
        differencePreviousYear: differenceCurrentYear - deltaCurrent,
        yearlyEntitlement: data.yearlyEntitlement
      };
    }

    const differencePreviousYear = Math.min(
      data.remainingPreviousYear,
      differenceCurrentYear
    );
    differenceCurrentYear -= differencePreviousYear;
    return {
      user: { ...data.user },
      month: data.month,
      absences: [...data.absences],
      year: data.year,
      plannedAbsences: data.plannedAbsences,
      remainingEntitlement: data.remainingEntitlement - differenceCurrentYear,
      differenceCurrentYear,
      remainingPreviousYear:
        data.remainingPreviousYear - differencePreviousYear,
      differencePreviousYear,
      yearlyEntitlement: data.yearlyEntitlement
    };
  }

  getChangesForRoleData(
    userDataBefore: AbsencesInMonthlyOverview[],
    userDatAfter: AbsencesInMonthlyOverview[]
  ): ChangeData[] {
    const newData: ChangeData[] = [];
    for (let i = 0; i < userDataBefore.length; i++) {
      const currentUserData = userDataBefore[i];
      const newUserData = userDatAfter[i];
      if (
        this.absencesChanged(currentUserData.absences, newUserData.absences)
      ) {
        newData.push(
          this.mapFetchedDataToChangeData(
            newUserData,
            this.entitlementValue(newUserData.absences) -
            this.entitlementValue(currentUserData.absences)
          )
        );
      }
    }

    return newData;
  }

  onDetectChanges() {
    const changedData: ChangeData[] = [];

    if (this.fetchedAbsencesData && this.editedAbsencesData) {
      for (const role of Object.keys(this.userRoles)) {
        if (this.fetchedAbsencesData[role] && this.editedAbsencesData[role]) {
          changedData.push(
            ...this.getChangesForRoleData(
              this.fetchedAbsencesData[role],
              this.editedAbsencesData[role]
            )
          );
        }
      }
    }

    this.changeData = changedData;
  }

  formatDifference(difference: number): string {
    if (difference > 0) {
      return `(-${difference.toFixed(1)})`;
    } else if (difference < 0) {
      return `(+${Math.abs(difference).toFixed(1)})`;
    }
    return "";
  }

  markAsHoliday(day: number) {
    for (const userRole in this.userRoles) {
      if (this.editedAbsencesData && this.editedAbsencesData[userRole]) {
        for (const absence of this.editedAbsencesData[userRole]) {
          absence.absences[day - 1] = "F";
        }
      }
    }

    // actual update the data
    this.onDetectChanges();

    // To ensure the content is updated, a reload of the whole site is triggered here.
    // According to https://vuejs.org/v2/guide/list.html#Caveats
    // Object.assign or Vue.set or this.$set or .splice *should* work...
    // Alas, it doesn't.
    this.$forceUpdate();
  }

  discardChanges() {
    if (this.hasUnsavedChanges()) {
      if (confirm("Die Eingaben werden gelöscht. Bestätigen?")) {
        this.setInitialEditableAbsencesData();
        this.onToggleDataTableEditMode();
      }
    } else {
      this.onToggleDataTableEditMode();
    }
  }

  createChangesForRequest() {
    const dateFormat = YEAR_MONTH_DAY_DATEFORMAT;

    return this.changeData.map((change: ChangeData) => {
      const absences: AbsenceType[] = [];
      for (let i = 0; i < change.absences.length; i++) {
        const detectedChangesInAbsences = change.absences[i];
        if (detectedChangesInAbsences) {
          const absenceDay = formatDateToFormatStringInUTCTz(
            new Date(change.year, change.month - 1, i + 1),
            dateFormat
          );
          absences.push({
            date: absenceDay.toString(),
            type: detectedChangesInAbsences
          });
        }
      }

      return {
        affectedEmployee: change.user.userId,
        data: absences,
        startOfRange: formatDateToFormatStringInUTCTz(
          new Date(change.year, change.month - 1, 1),
          dateFormat
        ),
        endOfRange: formatDateToFormatStringInUTCTz(
          new Date(change.year, change.month, 0),
          dateFormat
        ),
        remark: this.remarks[change.user.userId]
      };
    });
  }

  submitAbsences(): Promise<AxiosPromise | void> {
    const absencesToUpdate: AbsencesToUpdate[] = this.createChangesForRequest();
    if (absencesToUpdate.length) {
      return this.axios.post("/api/updateAbsenceStatus", absencesToUpdate);
    } else {
      // empty promise to show that nothing was submitted
      return Promise.resolve();
    }
  }

  closeSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  saveData() {
    this.submitAbsences()
      .then(response => {
        if (response) {
          this.loadData();
          this.transactionStatus =
            "Alle Abwesenheiten wurden erfolgreich eingetragen/beantragt";
          this.snackbar = true;
        }
        this.onToggleDataTableEditMode();
      })
      .catch(reason => {
        console.log(reason);

        this.transactionStatus = getUserMessageForErrorReason(reason);
        this.transactionError = true;
        this.snackbar = true;
      });
  }

  getFormattedMonthDay(day: number) {
    return formatDateToFormatStringInUTCTz(
      new Date(this.year, this.month - 1, day),
      "EE d."
    );
  }

  get userHasReadRights(): boolean {
    return hasReadRights(this.userMetaData);
  }

  userHasWritePermissions(): boolean {
    return this.userMetaData.isAdmin || this.userMetaData.isManager;
  }
}
</script>

<style lang="scss">
.monthly-overview-wrapper {
  overflow-x: auto;

  .monthly-overview-layout {
    min-width: 1500px;

    .yearly-overview-layout {
      position: relative;
      overflow-y: hidden;

      &.overflowed-layout-view {
        .table-body {
          overflow-y: auto;
        }
      }

      .absence-table {
        .absence-type-select {
          padding: 5px 0;
        }

        .weekend {
          background-color: #d4d8f9 !important;
          min-width: 0.6em;
          max-width: 0.6em;
        }

        .remark-month {
          width: 10%;

          .remark-to-add {
            margin-top: unset;
            padding: 5px;
          }
        }

        .absences-in-day-monthly {
          &:not(.weekend) {
            min-width: 1em;
            max-width: 1em;
          }

          .v-select__selections {
            width: 50%;

            .v-select__selection {
              margin: 0 4px;
            }

            input[readonly="readonly"] {
              display: none;
            }
          }
        }

        .user-name-month {
          min-width: 4em;
          max-width: 4em;
          justify-content: center;
          word-wrap: break-word;
          white-space: normal;
        }
      }
    }

    .no-absence-date,
    .loading-data-error {
      padding: 7.5em;
      font-size: calc(1rem + 2px);
    }

    .planned-absence {
      background-color: palegreen;
    }
  }
}

.toolbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  .remaining-entitlement-wrapper {
    display: inline-block;
    flex-grow: 5;

    .changed-remaining-entitlement {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      > div {
        padding: 0 2em;

        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }

  .edit-mode-buttons {
    flex-shrink: 5;
    flex-basis: 104px;
    display: inline;
  }
}

.v-dialog__content {
  .v-dialog--active {
    .headline {
      background-color: var(--v-primary-base);
      color: white;
    }
  }
}

.title {
  font-size: smaller !important;
}
</style>
