<template>
  <div class="change-history-layout">
    <v-snackbar
      v-model="snackbar"
      :color="transactionError ? 'error' : 'success'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="closeSnackbar()"> Schließen</v-btn>
    </v-snackbar>

    <v-layout justify-center row v-if="userHasReadRights">
      <v-flex>
        <v-spacer />
      </v-flex>
      <v-flex>
        <v-autocomplete
          :value="selectedUser"
          :type="'User'"
          :disabled="!usersLoaded"
          :loading="!usersLoaded"
          :items="users"
          :item-text="userItemText"
          return-object
          label="Person"
          @change="user=>onChangeUser(user)"
          :no-data-text="noDataPlaceholder"
          required
          hide-selected
        ></v-autocomplete>
      </v-flex>
      <v-flex>
        <v-spacer />
      </v-flex>
    </v-layout>
    <div v-show="changeHistoryLoaded">
      <span class="sort-text">Sortierung:</span>
      <v-btn @click="reverseSorting" color="info">
        {{ (sortOldestFirst ? "Ält" : "Neu") + "este zuerst" }}
      </v-btn>

      <v-data-table
        :headers="tableHeaders"
        :items="changeHistory"
        class="absence-table text-sm-center"
        :hide-actions="!!changeHistory ? changeHistory.length <= 30 : true"
        :rows-per-page-items="[
          30,
          { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }
        ]"
        disable-initial-sort
      >
        <!-- TODO: style headers like in yearlyOverview -->
        <template slot="headerCell" slot-scope="props">
          <span>{{ props.header.text }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td v-if="props.item.creator !== null">
            {{ props.item.creator.lastName }},
            {{ props.item.creator.firstName }}
          </td>
          <td v-else>
            SYSTEM
          </td>
          <td>{{ formatDate(props.item.creationDate) }}</td>
          <td>{{ props.item.year }}</td>
          <td :class="addedOrRemovedClass(props.item.sumDifference)">
            {{ props.item.sum + formatDifference(props.item.sumDifference) }}
          </td>
          <td
            :class="addedOrRemovedClass(props.item.yearlyEntitlementDifference)"
          >
            {{
              roundToTwoDecimals(props.item.yearlyEntitlement) +
                formatDifference(props.item.yearlyEntitlementDifference)
            }}
          </td>
          <td
            :class="addedOrRemovedClass(props.item.remainingVacationDifference)"
          >
            {{
              roundToTwoDecimals(props.item.remainingVacation) +
                formatDifference(props.item.remainingVacationDifference)
            }}
          </td>
          <td
            :class="
              addedOrRemovedClass(
                props.item.remainingVacationFromLastYearDifference
              )
            "
          >
            {{
              roundToTwoDecimals(props.item.remainingVacationFromLastYear) +
                formatDifference(
                  props.item.remainingVacationFromLastYearDifference
                )
            }}
          </td>
          <td :class="addedOrRemovedClass(props.item.sicknessDifference)">
            {{
              props.item.sickness +
                formatDifference(props.item.sicknessDifference)
            }}
          </td>
          <td :class="addedOrRemovedClass(props.item.childSickDifference)">
            {{
              props.item.childSick +
                formatDifference(props.item.childSickDifference)
            }}
          </td>
          <td :class="addedOrRemovedClass(props.item.workingHoursDeleted)">
            <v-tooltip
              bottom
              max-width="400"
              :disabled="!props.item.workingHoursInMinutes"
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  formatWorkingHoursInMinutes(props.item.workingHoursInMinutes)
                }}</span>
              </template>
              <div class="tooltip-text" v-if="props.item.workingHoursInMinutes">
                <span>{{
                  formatWorkingHoursInMinutes(props.item.workingHoursInMinutes)
                }}</span>
                <span
                  >Gültig ab:
                  {{
                    getDateContext(
                      props.item.workingHoursValidFrom
                    ).toLocaleDateString(DATEPICKER_INPUT_DATE_FORMAT)
                  }}</span
                >
                <span v-if="props.item.workingHoursDeleted"
                  >Diese Soll-Arbeitszeit wurde gelöscht</span
                >
              </div>
            </v-tooltip>
          </td>
          <td
            class="remark"
            :ref="`remark_${props.item.creationDate}`"
            :class="{
              ellipsized: isOverflowingTableCell(
                `remark_${props.item.creationDate}`
              )
            }"
          >
            <v-tooltip
              bottom
              :disabled="
                !remarkCellsOverflowStates[`remark_${props.item.creationDate}`]
              "
              max-width="400"
            >
              <template v-slot:activator="{ on }">
                <span v-on="on" class="remark-text">{{
                  props.item.remark ? props.item.remark : "—"
                }}</span>
              </template>
              <span class="tooltip-text">{{
                props.item.remark ? props.item.remark : "—"
              }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-tooltip
              dark
              left
              :disabled="
                Object.values(props.item.changesInMonths).filter(val => !!val)
                  .length !== 0
              "
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    @click="openDetailView(props.item)"
                    :disabled="
                      Object.values(props.item.changesInMonths).filter(
                        val => !!val
                      ).length === 0
                    "
                    large
                  >
                    pageview
                  </v-icon>
                </div>
              </template>
              <span class="tooltip-text"
                >Es gab in keinem Monat eine Änderung</span
              >
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </div>

    <v-dialog lazy v-model="dialog">
      <v-card>
        <v-card-title>
          <v-layout justify-center row>
            <v-flex>
              <h2>Detail Ansicht</h2>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="entryInDetailView"
            :headers="detailHeaders"
            hide-actions
            :rows-per-page-items="[12]"
            :items="itemsForDetailView(entryInDetailView.changesInMonths)"
            class="absence-table overview text-sm-center"
          >
            <template slot="headerCell" slot-scope="props">
              <span>{{ props.header.text }}</span>
            </template>
            <template slot="items" slot-scope="props">
              <td
                v-for="(change, index) in props.item"
                :key="index"
                :class="change.absenceChange.toLowerCase()"
              >
                <v-tooltip dark left :disabled="!change.typeBeforeChange">
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      :class="
                        change.absenceChange === 'NO_CHANGE'
                          ? ''
                          : ' heavier-font'
                      "
                      >{{ contentForAbsenceChange(change) }}</span
                    >
                  </template>
                  <span class="tooltip-text"
                    >Vorher: '{{ change.typeBeforeChange }}'</span
                  >
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end row>
            <v-flex grow>
              <v-spacer />
            </v-flex>
            <v-flex shrink>
              <v-btn color="primary" @click="dialog = false"> Schließen</v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";
import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  ABSENCE_DATA_MUTATIONS,
  AbsenceSpace
} from "@/store/modules/absenceData";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { format } from "date-fns";

import {
  DATEPICKER_INPUT_DATE_FORMAT,
  MONTH_APRIL,
  MONTH_AUGUST,
  MONTH_DECEMBER,
  MONTH_FEBRUARY,
  MONTH_JANUARY,
  MONTH_JULY,
  MONTH_JUNE,
  MONTH_MARCH,
  MONTH_MAY,
  MONTH_NOVEMBER,
  MONTH_OCTOBER,
  MONTH_SEPTEMBER,
  monthNamesByKey,
  PLACEHOLDER_NO_SELECTABLE_DATA
} from "@/constants";
import {
  AbsenceChange,
  ChangeHistoryEntry,
  ChangesInMonths,
  User,
  UserMetaData
} from "@/models";
import {
  formatDateToFormatStringInUTCTz,
  formatWorkingHoursInMinutes,
  getDateContext,
  getUserMessageForErrorReason,
  hasReadRights,
  roundToTwoDecimals
} from "@/utils";

@Component({
  methods: {
    DATEPICKER_INPUT_DATE_FORMAT() {
      return DATEPICKER_INPUT_DATE_FORMAT;
    }
  }
})
export default class ChangeHistory extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  users!: User[];
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getChangeHistory)
  changeHistory!: ChangeHistoryEntry[];
  @AbsenceSpace.Mutation(ABSENCE_DATA_MUTATIONS.reverseChangeHistory)
  reverseChangeHistory!: () => void;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchChangeHistory)
  fetchChangeHistory!: (user: string) => Promise<ChangeHistoryEntry[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  selectedUsers!: User[];
  @AbsenceSpace.Mutation(ABSENCE_DATA_MUTATIONS.setSelectedUsers)
  setSelectedUsers!: (selectedUsers: User[]) => void;

  subject$: Subject<void> = new Subject();
  transactionStatus = "";
  snackbar = false;
  transactionError = false;
  usersLoaded = false;
  selectedUser: User | null = null;
  changeHistoryLoaded = false;
  tableHeaders: object[] = [];
  detailHeaders: object[] = [];
  dialog = false;
  entryInDetailView: ChangeHistoryEntry | null = null;
  sortOldestFirst = true;

  formatWorkingHoursInMinutes = formatWorkingHoursInMinutes;
  getDateContext = getDateContext;
  roundToTwoDecimals = roundToTwoDecimals;

  remarkCellsOverflowStates = {} as { [key: string]: boolean };

  created() {
    window.addEventListener("resize", this.onResize);
  }

  updated() {
    this.onResize();
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  mounted() {
    this.setTableHeaders();
    this.$subscribeTo(
      this.subject$.pipe(debounceTime(300)),
      () =>
        this.selectedUser && this.getUserChangeHistory(this.selectedUser.userId)
    );

    this.fetchAllUsers().then(() => {
      this.usersLoaded = true;
      if (!this.userHasReadRights) {
        this.selectedUser = this.users.find(
          ({ userId }) => userId === this.userMetaData.userId
        ) as User;
      } else {
        this.selectedUser = this.selectedUsers[0];
      }
      this.selectedUser && this.getUserChangeHistory(this.selectedUser.userId);
    });
  }

  getUserChangeHistory(userId: string) {
    this.fetchChangeHistory(userId)
      .then(() => {
        this.sortOldestFirst = true;
        this.changeHistoryLoaded = true;
      })
      .catch(reason => {
        this.changeHistoryLoaded = false;
        console.log(reason);

        if (reason.response.status === 404) {
          this.snackbar = true;
          this.transactionError = false;
          this.transactionStatus =
            "Für diesen User gibt es noch keine Änderungen";
        } else {
          this.transactionError = true;
          this.transactionStatus = getUserMessageForErrorReason(reason);
          this.snackbar = true;
        }
      });
  }

  userItemText(user: User) {
    return user.firstName + ", " + user.lastName + " (" + user.userRole + ")";
  }

  closeSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  openDetailView(entry: ChangeHistoryEntry) {
    this.dialog = true;
    this.entryInDetailView = entry;
  }

  formatDifference(value: number): string {
    if (value === 0) {
      return "";
    } else {
      return (value > 0 ? " (+" : " (") + roundToTwoDecimals(value) + ")";
    }
  }

  addedOrRemovedClass(differenceOrDeleted: number | boolean): string {
    if (
      differenceOrDeleted === 0 ||
      differenceOrDeleted === null ||
      differenceOrDeleted === undefined
    ) {
      return "";
    } else if (typeof differenceOrDeleted === "number") {
      differenceOrDeleted = differenceOrDeleted < 0;
    }
    return differenceOrDeleted
      ? "removed-overview heavier-font"
      : "added-overview heavier-font";
  }

  itemsForDetailView(changesInMonths: ChangesInMonths): AbsenceChange[][] {
    const result: AbsenceChange[][] = [];

    const monthOrder = [
      MONTH_JANUARY,
      MONTH_FEBRUARY,
      MONTH_MARCH,
      MONTH_APRIL,
      MONTH_MAY,
      MONTH_JUNE,
      MONTH_JULY,
      MONTH_AUGUST,
      MONTH_SEPTEMBER,
      MONTH_OCTOBER,
      MONTH_NOVEMBER,
      MONTH_DECEMBER
    ];

    for (const monthKey of monthOrder) {
      if (changesInMonths[monthKey]) {
        const newEntry: AbsenceChange[] = [];
        newEntry.push({
          type: monthNamesByKey[monthKey],
          absenceChange: "NO_CHANGE",
          typeBeforeChange: ""
        });
        newEntry.push(...changesInMonths[monthKey]);
        result.push(newEntry);
      }
    }

    return result;
  }

  contentForAbsenceChange(change: AbsenceChange): string {
    if (change.absenceChange !== "REMOVED") {
      return change.type;
    } else {
      return change.typeBeforeChange;
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return formatDateToFormatStringInUTCTz(date, "dd.MM.yyyy HH:mm");
  }

  get noDataPlaceholder() {
    return PLACEHOLDER_NO_SELECTABLE_DATA;
  }

  get userHasReadRights() {
    return hasReadRights(this.userMetaData);
  }

  reverseSorting() {
    this.sortOldestFirst = !this.sortOldestFirst;
    this.reverseChangeHistory();
  }

  private setTableHeaders() {
    this.tableHeaders = [
      {
        text: "Bearbeitet von",
        value: "creator",
        sortable: false,
        class: "creator"
      },
      {
        text: "Datum",
        value: "creationDate",
        sortable: false,
        class: "creation-date"
      },
      {
        text: "Betroffenes Jahr",
        value: "year",
        sortable: false,
        class: "year"
      },
      {
        text: "Summe eingereichter Urlaube",
        value: "sumVacations",
        sortable: false,
        class: "sum-vacations"
      },
      {
        text: "Anzahl Urlaub Gesamt p.a.",
        value: "yearlyEntitlement",
        sortable: false,
        class: "yearly-entitlement"
      },
      {
        text: "Verfügbare Urlaubstage",
        value: "remainingVacation",
        sortable: false,
        class: "remaining-vacation"
      },
      {
        text: "Resturlaub Vorjahr",
        value: "remainingVacationFromLastYear",
        sortable: false,
        class: "remaining-vacation"
      },
      {
        text: "Summe Krankheit",
        value: "sickness",
        sortable: false,
        class: "sickness"
      },
      {
        text: "Summe Kindkrank",
        value: "childSick",
        sortable: false,
        class: "child-sick"
      },
      {
        text: "Soll-Arbeitszeit Änderung",
        value: "workingHours",
        sortable: false,
        class: "working-hours"
      },
      {
        text: "Anmerkung",
        value: "remark",
        sortable: false,
        class: "remark"
      },
      {
        text: "Detailansicht",
        value: "detailView",
        sortable: false,
        class: "detail-view"
      }
    ];
    this.setDetailTableHeaders();
  }

  private setDetailTableHeaders() {
    this.detailHeaders = [
      {
        text: "Monat",
        value: "month",
        sortable: false
      }
    ];
    for (let i = 1; i <= 31; i++) {
      this.detailHeaders.push({
        text: i.toString(10),
        value: i.toString(10),
        sortable: false
      });
    }
  }

  private onResize() {
    Object.keys(this.$refs).forEach((refKey: string) => {
      this.remarkCellsOverflowStates = {
        ...this.remarkCellsOverflowStates,
        [refKey]: this.isOverflowingTableCell(refKey)
      };
    });
  }

  onChangeUser(user: User) {
    this.selectedUser = user;
    this.subject$.next();
  }

  private isOverflowingTableCell(refKey: string) {
    const tableCellRef = this.$refs[refKey] as HTMLElement;
    return tableCellRef && tableCellRef.scrollWidth > tableCellRef.clientWidth;
  }
}
</script>

<style lang="scss">
.change-history-layout,
.v-dialog {
  .heavier-font {
    font-weight: 600;
  }

  .added {
    color: var(--v-success-darken1);
    border-color: #000000;
  }

  .removed {
    color: var(--v-error-darken1);
    text-decoration: line-through;
    border-color: #000000;
  }

  .changed {
    color: var(--v-warning-base);
    border-color: #000000;
  }

  .added-overview {
    color: var(--v-success-darken1);
    border-color: #000000;
  }

  .removed-overview {
    color: var(--v-error-darken1);
    border-color: #000000;
  }

  .absence-table {
    border-top: 1px solid;
    margin: 0 1.5%;

    table.v-datatable.v-table {
      thead,
      tbody {
        tr {
          border-left: 1px dotted;
          border-right: 1px dotted;
        }
      }

      thead {
        th {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      tbody {
        td {
          padding: 0 10px;

          &.remark {
            white-space: nowrap;
            min-width: 5em;
            max-width: 12em;

            &.ellipsized {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        tr {
          border-left: 1px dotted;

          &:last-child {
            border-bottom: 1px solid black;
          }

          td:last-child {
            border-right: 1px dotted;
          }
        }
      }
    }
  }
}

.tooltip-text {
  font-size: 140%;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.sort-text {
  font-size: calc(1rem + 4px);
}

@media only screen and (max-width: 1000px) {
  .change-history-layout {
    div.absence-table:not(.overview) {
      table.v-datatable {
        th.column {
          min-width: 70px;
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &::after {
            display: block;
            position: absolute;
            top: 12.5%;
            background-color: #555555;
            color: #ffffff;
            text-align: center;
            padding: 5px;
            border-radius: 3px;
            visibility: hidden;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s;
          }

          &:hover::after {
            visibility: visible;
            opacity: 0.9;
            font-size: 1rem;
            content: attr(aria-label);
          }
        }
      }
    }
  }
}
</style>
