<template>
  <RequestForm :fetch-preselected-recipients="fetchRecipientsOfLatestRequestByUser"
               endpoint-url="/api/newAbsenceRequest"
               place-holder-user-selection="Ansprechpartner auswählen oder Mail eingeben und mit Enter bestätigen"
               comment-label="Kommentar (optional)"
               submit-button-label="Neuen Urlaubsantrag erstellen"
               allow-new-users>
  </RequestForm>
</template>

<script lang="ts">
import Component from "vue-class-component";

import {Recipient} from "@/models";

import RequestForm from "./requestForm.vue";
import Vue from "vue";

@Component({
  components: {
    RequestForm,
  }
})
export default class VacationRequest extends Vue {
  fetchRecipientsOfLatestRequestByUser = (): Promise<Recipient[]> => this.axios
    .get("/api/getLatestRequestByUser")
    .then(response => (response.data && response.data.recipients) || []);
}
</script>
