<template>
  <div>
    <div class="monthly-overview-wrapper" ref="tableWrapper">
      <div class="monthly-overview-layout">
        <v-snackbar
          v-model="snackbar"
          :color="transactionError ? 'error' : 'success'"
          top
        >
          {{ transactionStatus }}
          <v-btn color="white" flat @click="closeSnackbar()">Schließen</v-btn>
        </v-snackbar>
        <div
          v-if="requestHandled"
          class="yearly-overview-layout"
          :class="{
            'overflowed-layout-view': userHasReadRights
          }"
        >
          <v-data-table
            :headers="isMobile ? monthHeadersMobile : monthHeaders"
            class="absence-table text-sm-center table-header"
            :items="[]"
            hide-actions
            ref="tableHeader"
          >
            <template v-slot:no-data>
              <tr style="visibility: hidden"></tr>
            </template>
          </v-data-table>
          <div class="table-body" ref="tableBody">
            <div
              v-for="role in involvedUserRoles"
              :key="role"
              :id="role"
            >
              <div v-if="fetchedAbsencesData">
                <div v-if="userHasReadRights" class="user-role-row">
                  {{ role }}
                </div>

                <v-data-table
                  :items="filterByRole(role)"
                  class="absence-table"
                  hide-headers
                  hide-default-footer
                  hide-actions
                >
                  <template v-slot:items="props">
                    <td class="user-name-month">
                      {{ props.item.user.lastName }},
                      {{ props.item.user.firstName }}
                    </td>
                    <td
                      class="absences-in-day-monthly text-sm-center"
                      v-for="(headerDate, index) in monthHeaders.slice(1)"
                      :class="{
                        absent: props.item.absences[index] !== undefined
                      }"
                      :key="index"
                    >
                      <!-- Because monthHeaders starts with Name at 0 position -->
                      <div
                        v-for="(value, key) in Object(props.item.absences)"
                        :key="key"
                        v-if="getFormattedMonthDay(key) === headerDate.text"
                      >
                        {{ value }}
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div id="loadingSpinner" v-if="!requestHandled"></div>
          <div v-else class="loading-data-error">
            <strong>
              Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
              {{ errorLoadingData }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

import UserFilter from "../common/userFilter.vue";
import {
  MINIMAL_RESPONSIVE_WIDTH,
  TABLE_HEADER_DATE_FORMAT,
  TABLE_HEADER_DATE_FORMAT_MOBILE
} from "@/constants";
import { Absences, UpcomingAbsences, User, UserMetaData } from "@/models";
import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {
  formatDateToFormatStringInUTCTz,
  getDateContext,
  getUserMessageForErrorReason,
  hasReadRights,
  isWeekend
} from "@/utils";

@Component({ components: { userFilter: UserFilter } })
export default class AbsenceOverview extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUpcomingAbsences)
  fetchedAbsencesData!: UpcomingAbsences[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUpcomingAbsences)
  fetchUpcomingAbsences!: () => Promise<Absences<UpcomingAbsences>>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  allUsers!: User[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUserMetaData)
  fetchUserMetaData!: () => Promise<UserMetaData>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];

  transactionStatus = "";
  snackbar = false;
  transactionError = false;
  requestHandled = false;
  errorLoadingData = null;
  weekendDays: number[] = [];

  isMobile: boolean = false;

  monthHeaders = [
    {
      text: "Name",
      value: "name",
      sortable: true,
      class: ""
    }
  ];

  monthHeadersMobile = [
    {
      text: "Name",
      value: "name",
      sortable: true,
      class: ""
    }
  ];

  created() {
    window.addEventListener("resize", this.onResize);
  }

  mounted() {
    this.fetchUserMetaData()
      .then(() => {
        this.loadData().then(() => {
          this.updateDayHeaders();
        });
      })
      .catch(error => {
        console.warn(error);
        this.transactionStatus = getUserMessageForErrorReason(error);
        this.transactionError = true;
        this.snackbar = true;
      });
  }

  updateDayHeaders() {
    this.weekendDays = [];
    this.monthHeaders = [
      {
        text: "Name",
        value: "name",
        sortable: false,
        class: "user-name-month"
      }
    ];
    this.monthHeadersMobile = [...this.monthHeaders];

    let mergedItems: { [key: string]: string } = {};

    this.fetchedAbsencesData.forEach((item: UpcomingAbsences) => {
      const absences = Object.fromEntries(
        Object.entries(item.absences).sort((a, b) => {
          return new Date(a[0]).getTime() - new Date(b[0]).getTime();
        })
      );
      mergedItems = { ...mergedItems, ...absences };
    });

    const sortedArray = Object.entries(mergedItems).sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    );
    const sortedAndMergedItems = Object.fromEntries(sortedArray);

    for (const absencesKey in sortedAndMergedItems) {
      if (sortedAndMergedItems.hasOwnProperty(absencesKey)) {
        const currentDate = new Date(absencesKey);
        const columnClass = "absences-in-day-monthly";
        const headerObject = this.createHeaderObject(
          currentDate,
          0,
          columnClass,
          false
        );
        const headerObjectMobile = this.createHeaderObject(
          currentDate,
          0,
          columnClass,
          true
        );

        if (
          !isWeekend(currentDate) &&
          !this.monthHeaders.some(
            headerItem => headerItem.text === headerObject.text
          )
        ) {
          this.monthHeaders.push(headerObject);
          this.monthHeadersMobile.push(headerObjectMobile);
        }
      }
    }
  }

  createHeaderObject(
    currentDate: Date,
    counter: number,
    columnClass: string,
    isMobile: boolean
  ) {
    const headerText = isMobile
      ? formatDateToFormatStringInUTCTz(
        currentDate,
        TABLE_HEADER_DATE_FORMAT_MOBILE
      )
      : formatDateToFormatStringInUTCTz(currentDate, TABLE_HEADER_DATE_FORMAT);

    return {
      text: headerText,
      value: counter.toString(10),
      sortable: false,
      class: columnClass
    };
  }

  getFormattedMonthDay(date: string | number) {
    return formatDateToFormatStringInUTCTz(
      getDateContext(date),
      TABLE_HEADER_DATE_FORMAT
    );
  }

  updated() {
    this.onResize();
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  async loadData() {
    this.requestHandled = false;
    return this.fetchUpcomingAbsences()
      .catch(err => this.errorLoadingData = err)
      .finally(() => {
        this.requestHandled = true;
      });
  }

  onResize() {
    const timeout = setTimeout(() => {
      this.isMobile = window.innerWidth < MINIMAL_RESPONSIVE_WIDTH;
      clearTimeout(timeout);
    }, 300);
  }

  closeSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  filterByRole(role: string): any[] {
    if (this.fetchedAbsencesData && Array.isArray(this.fetchedAbsencesData)) {
      return this.fetchedAbsencesData.filter(item => {
        return item.user.userRole === role;
      });
    }
    return [];
  }

  get userHasReadRights(): boolean {
    return hasReadRights(this.userMetaData);
  }

  get involvedUserRoles() {
    const result: string[] = [];
    if (this.fetchedAbsencesData && Array.isArray(this.fetchedAbsencesData)) {
      this.fetchedAbsencesData.forEach(userData => {
        const userRole = userData.user.userRole;
        if (!result.includes(userRole)) {
          result.push(userRole);
        }
      });
    }
    return result;
  }
}
</script>

<style lang="scss">
.monthly-overview-wrapper {
  overflow-x: auto;

  .monthly-overview-layout {
    min-width: 1500px;

    .yearly-overview-layout {
      position: relative;
      overflow-y: hidden;

      &.overflowed-layout-view {
        .table-body {
          overflow-y: auto;
        }
      }

      .absence-table {
        .absence-type-select {
          padding: 5px 0;
        }

        .absent {
          background-color: #cddaecff !important;
          min-width: 0.6em;
          max-width: 0.6em;
        }

        .remark-month {
          width: 10%;

          .remark-to-add {
            margin-top: unset;
            padding: 5px;
          }
        }

        .absences-in-day-monthly {
          .v-select__selections {
            width: 50%;

            .v-select__selection {
              margin: 0 4px;
            }

            input[readonly="readonly"] {
              display: none;
            }
          }
        }

        .user-name-month {
          width: 10%;
          justify-content: center;
          word-wrap: break-word;
          white-space: nowrap;
        }
      }
    }

    .no-absence-date,
    .loading-data-error {
      padding: 7.5em;
      font-size: calc(1rem + 2px);
    }
  }
}

.toolbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  .remaining-entitlement-wrapper {
    display: inline-block;
    flex-grow: 5;

    .changed-remaining-entitlement {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      > div {
        padding: 0 2em;

        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }

  .edit-mode-buttons {
    flex-shrink: 5;
    flex-basis: 104px;
    display: inline;
  }
}

.v-dialog__content {
  .v-dialog--active {
    .headline {
      background-color: var(--v-primary-base);
      color: white;
    }
  }
}

.title {
  font-size: smaller !important;
}
</style>
