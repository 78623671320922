<template>
  <div class="text-xs-center">
    <v-dialog lazy persistent width="500" v-model="showForm">
      <v-card>
        <v-card-title class="dialog-title">
          {{ title }}
        </v-card-title>

        <v-divider></v-divider>

        <div v-if="warning" class="warning-text">
          <span>{{ warning }}</span>
        </div>
        <v-form ref="form" v-model="isFormValid">
          <v-text-field
            label="Jahres-Anspruch"
            id="yearly-entitlement"
            type="number"
            class="entitlement-data"
            @input="reevaluateEntitlementFields()"
            :rules="[
              v => !!v || v === 0 || 'Der Anspruch muss eingeben werden',
              v => v >= 0 || 'Anspruch kann nicht negativ sein'
            ]"
            v-model.number="entitlementData.yearlyEntitlement"
          >
          </v-text-field>

          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-text-field
                  label="Resturlaub"
                  class="entitlement-data"
                  type="number"
                  id="remaining-entitlement"
                  v-model.number="entitlementData.remainingEntitlement"
                  disabled
                >
                </v-text-field>
              </div>
            </template>
            <span>
              Änderungen am Jahresanspruch werden automatisch übernommen
            </span>
          </v-tooltip>
          <v-text-field
            v-if="$props.showYearSelection"
            label="Jahr"
            type="number"
            class="entitlement-data"
            id="year"
            :rules="[
              v => !!v || 'Ein Jahr muss angegeben werden',
              v =>
                (Math.floor(v) === Number(v) && v >= 0) || 'Kein gültiges Jahr'
            ]"
            v-model.number="entitlementData.year"
          >
          </v-text-field>

          <v-text-field
            label="Anmerkung zur Änderung (Optional)"
            id="remark"
            class="entitlement-data"
            v-model="entitlementData.remark"
          >
          </v-text-field>

          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="primary"
              :disabled="!isFormValid"
              class="save-entitlement"
              @click="submit(entitlementData)"
            >
              Speichern
            </v-btn>

            <v-btn color="error" class="discard-changes" @click="cancel()">
              Abbrechen
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { EntitlementData } from "@/models";
import { AxiosResponse } from "axios";

@Component
export default class EntitlementForm extends Vue {
  @Prop(Boolean) showForm!: boolean;
  @Prop(Boolean) showYearSelection!: boolean;
  @Prop(String) title!: string;
  @Prop(Function) submit!: (data: EntitlementData) => Promise<AxiosResponse>;
  @Prop(Function) cancel!: () => void;
  @Prop(Object) initialData?: EntitlementData;
  @Prop(String) warning?: string;

  entitlementData = {} as EntitlementData;
  isFormValid = false;

  previousYearlyEntitlement: number = 0;
  previousRemainingEntitlement: number = 0;

  mounted() {
    if (this.initialData) {
      this.entitlementData = JSON.parse(JSON.stringify(this.initialData));

      if (this.entitlementData.yearlyEntitlement) {
        this.previousYearlyEntitlement = this.entitlementData.yearlyEntitlement;
        this.previousRemainingEntitlement = this.entitlementData.remainingEntitlement;
      }
    } else if (this.showYearSelection) {
      this.setInitialYear();
    }
  }

  setInitialYear() {
    if (this.showYearSelection) {
      const initialDate = new Date();
      // goes to previous year if current month is january, see CPT-88
      initialDate.setDate(-1);
      this.entitlementData.year = initialDate.getFullYear();
    }
  }

  reevaluateEntitlementFields() {
    if (this.previousYearlyEntitlement === 0) {
      this.entitlementData.remainingEntitlement = this.entitlementData.yearlyEntitlement;

      return;
    }

    this.entitlementData.remainingEntitlement =
      this.entitlementData.yearlyEntitlement -
      this.previousYearlyEntitlement +
      this.previousRemainingEntitlement;
  }

  hasUnsavedChanges() {
    return !!Object.entries(this.entitlementData).length;
  }
}
</script>

<style lang="scss">
.warning-text {
  border: 5px solid var(--v-warning-base);
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 16px;
}
</style>
