var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-xs-center"},[_c('v-dialog',{attrs:{"lazy":"","persistent":"","width":"500"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"dialog-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-divider'),(_vm.warning)?_c('div',{staticClass:"warning-text"},[_c('span',[_vm._v(_vm._s(_vm.warning))])]):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{staticClass:"entitlement-data",attrs:{"label":"Jahres-Anspruch","id":"yearly-entitlement","type":"number","rules":[
            v => !!v || v === 0 || 'Der Anspruch muss eingeben werden',
            v => v >= 0 || 'Anspruch kann nicht negativ sein'
          ]},on:{"input":function($event){return _vm.reevaluateEntitlementFields()}},model:{value:(_vm.entitlementData.yearlyEntitlement),callback:function ($$v) {_vm.$set(_vm.entitlementData, "yearlyEntitlement", _vm._n($$v))},expression:"entitlementData.yearlyEntitlement"}}),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-text-field',{staticClass:"entitlement-data",attrs:{"label":"Resturlaub","type":"number","id":"remaining-entitlement","disabled":""},model:{value:(_vm.entitlementData.remainingEntitlement),callback:function ($$v) {_vm.$set(_vm.entitlementData, "remainingEntitlement", _vm._n($$v))},expression:"entitlementData.remainingEntitlement"}})],1)]}}])},[_c('span',[_vm._v(" Änderungen am Jahresanspruch werden automatisch übernommen ")])]),(_vm.$props.showYearSelection)?_c('v-text-field',{staticClass:"entitlement-data",attrs:{"label":"Jahr","type":"number","id":"year","rules":[
            v => !!v || 'Ein Jahr muss angegeben werden',
            v =>
              (Math.floor(v) === Number(v) && v >= 0) || 'Kein gültiges Jahr'
          ]},model:{value:(_vm.entitlementData.year),callback:function ($$v) {_vm.$set(_vm.entitlementData, "year", _vm._n($$v))},expression:"entitlementData.year"}}):_vm._e(),_c('v-text-field',{staticClass:"entitlement-data",attrs:{"label":"Anmerkung zur Änderung (Optional)","id":"remark"},model:{value:(_vm.entitlementData.remark),callback:function ($$v) {_vm.$set(_vm.entitlementData, "remark", $$v)},expression:"entitlementData.remark"}}),_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"save-entitlement",attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":function($event){return _vm.submit(_vm.entitlementData)}}},[_vm._v(" Speichern ")]),_c('v-btn',{staticClass:"discard-changes",attrs:{"color":"error"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Abbrechen ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }