<template>
  <div
    v-if="editableUsersData.length && userMetaData.userId"
    class="update-user-info-layout"
  >
    <div v-if="userMetaData.isAdmin" class="change-menu">
      <v-btn icon>
        <v-icon
          class="save-user-changes-btn"
          @click="saveChanges"
          v-if="isEditMode"
          :disabled="!hasUnsavedChanges()"
          >done
        </v-icon>
        <v-icon
          class="change-user-data-btn"
          @click="onToggleEditMode"
          v-if="!isEditMode && !isCreateMode"
          >edit
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="() => (isCreateMode = true)"
        v-if="!isEditMode && !isCreateMode"
        class="add-new-user"
      >
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn
        icon
        color="error"
        v-if="isEditMode"
        @click="discardChanges"
        class="cancel-changes"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="isCreateMode"
        :disabled="isSaveNewUserDisabled()"
        @click="openEntitlementForm"
        class="add-new-user-to-list"
      >
        <v-icon>done</v-icon>
      </v-btn>
      <v-btn
        v-if="isCreateMode"
        color="error"
        class="cancel-new-user"
        icon
        @click="discardNewUser"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </div>

    <v-snackbar
      id="transaction-status-snackbar"
      v-model="snackbar"
      :color="transactionError ? 'error' : 'green'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="closeSnackbar()"> Schließen</v-btn>
    </v-snackbar>

    <v-data-table
      class="user-data-table"
      :headers="userDataHeaders"
      :items="[]"
      hide-actions
    >
      <template v-slot:no-data v-if="!isEditMode && isCreateMode">
        <td>
          <div class="user-name-update">
            <v-text-field
              id="new-user-last-name"
              class="new-user-data-item"
              v-model="newUserData.lastName"
              :rules="required('Nachname erforderlich!')"
              label="Nachname"
            ></v-text-field>
            <v-text-field
              v-model="newUserData.firstName"
              class="new-user-data-item"
              id="new-user-first-name"
              :rules="required('Vorname erforderlich!')"
              label="Vorname"
            ></v-text-field>
            <v-text-field
              v-model="newUserData.ldapName"
              class="new-user-data-item"
              id="new-user-ldap-name"
              :rules="required('Username erforderlich!')"
              label="Username"
            ></v-text-field>
          </div>
        </td>
        <td>
          <v-select
            :items="Object.values(userRoles)"
            id="new-user-role"
            class="new-user-role"
            v-model="newUserData.userRole"
            :rules="required('Rolle erforderlich!')"
            label="Rolle"
          ></v-select>
        </td>
        <td>
          <v-text-field
            type="email"
            v-model="newUserData.email"
            class="new-user-email"
            id="new-user-email"
            :rules="required('E-Mail-Adresse erforderlich!')"
            @click.once="userMailGenerator"
            label="E-mail"
          >
          </v-text-field>
        </td>
      </template>
      <template v-slot:no-data v-else>
        <tr style="visibility: hidden"></tr>
      </template>
    </v-data-table>
    <v-data-table
      must-sort
      :headers="userDataHeaders"
      :hide-headers="true"
      :custom-sort="userSort"
      :items="editableUsersData"
      hide-actions
      class="user-data-table"
    >
      <template v-slot:items="props">
        <tr>
          <td class="users">
            {{ props.item.lastName }}, {{ props.item.firstName }}
          </td>
          <td>
            <v-select
              class="user-roles change-role-selector"
              :disabled="!isEditMode"
              :items="Object.values(userRoles)"
              v-model="props.item.userRole"
            >
            </v-select>
          </td>
          <td>
            {{ props.item.email }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="shouldShowUnsavedWarning" width="500">
      <v-card>
        <v-card-title class="headline"> Bestätigen</v-card-title>

        <v-card-text>
          Diese Seite enthält ungespeicherte Änderungen! Diese gehen bei
          Verlassen der Seite verloren.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="leavePageWithoutSaving">
            Seite verlassen
          </v-btn>
          <v-btn color="primary" text @click="stayOnCurrentPage">Abbruch</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <entitlement-form
      v-if="shouldRenderEntitlementForm"
      :show-form="shouldShowEntitlementForm"
      :show-year-selection="true"
      :submit="createNewUser"
      :cancel="() => (shouldShowEntitlementForm = false)"
      :title="
        `Jahres-Anspruch für ${newUserData.firstName} ${newUserData.lastName} erstellen`
      "
    ></entitlement-form>
  </div>
  <div v-else>
    <div id="loadingSpinner" v-if="!requestHandled"></div>

    <div v-else-if="userMetaData.userId">
      <div class="no-absence-date" v-if="!errorLoadingData">
        <strong> Es gibt keine Einträge </strong>
      </div>

      <div v-else class="loading-data-error">
        <strong>
          Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
          {{ errorLoadingData }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "@/plugins/axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Route } from "vue-router";

import { MOBILE_WARNING, userRolesByKey } from "@/constants";
import { EntitlementData, User, UserMetaData } from "@/models";
import {
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS,
  AbsenceSpace
} from "@/store/modules/absenceData";
import {getUserMessageForErrorReason, required} from "@/utils";

import EntitlementForm from "./entitlement/entitlementForm.vue";

interface NewUserData extends User, EntitlementData {}

@Component({
  methods: {required},
  components: { entitlementForm: EntitlementForm } })
export default class UpdateUserInformation extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  fetchedUsers!: User[];
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;

  requestHandled = false;
  errorLoadingData = null;
  transactionStatus = "";
  editableUsersData: User[] = [];
  isEditMode = false;
  isCreateMode = false;
  userDataHeaders = [
    {
      text: "Name",
      value: "lastName",
      sortable: false,
      class: ""
    },
    {
      text: "Rolle",
      value: "userRole",
      sortable: false,
      class: ""
    },
    {
      text: "E-mail",
      value: "email",
      sortable: false,
      class: ""
    }
  ];
  requestType = "";
  transactionError = false;
  snackbar = false;
  userRoles = { ...userRolesByKey };

  newUserData = {} as NewUserData;

  shouldShowUnsavedWarning = false;
  shouldShowEntitlementForm = false;
  shouldRenderEntitlementForm = false;

  nextPageRoute = {} as Route;

  mounted() {
    this.loadData();

    if (this.isOnMobileBrowser() && this.userHasWritePermissions()) {
      this.transactionStatus = MOBILE_WARNING;
      this.snackbar = true;
      this.transactionError = true;
    }
  }

  beforeRouteLeave(to: Route, _from: Route, next: any) {
    if (this.hasUnsavedChanges()) {
      this.shouldShowUnsavedWarning = true;
      this.nextPageRoute = to;
    } else {
      next();
    }
  }

  loadData() {
    if (process.env.NODE_ENV !== "test") {
      this.fetchAllUsers()
        .then(() => {
          this.setInitialEditableUsersData();
        })
        .catch(error => {
          this.errorLoadingData = error;
        })
        .finally(() => {
          this.requestHandled = true;
        });
    } else {
      this.setInitialEditableUsersData();
    }
  }

  stayOnCurrentPage() {
    this.shouldShowUnsavedWarning = false;
    this.nextPageRoute = {} as Route;
  }

  leavePageWithoutSaving() {
    const {
      isCreateMode,
      isEditMode,
      nextPageRoute: { path }
    } = this;

    if (isCreateMode) {
      this.isCreateMode = false;
    }

    if (isEditMode) {
      this.setInitialEditableUsersData();
      this.onToggleEditMode();
    }

    this.$router.push(path);
  }

  hasUnsavedChanges() {
    const { isCreateMode, newUserData } = this;

    return (
      !!(isCreateMode && Object.entries(newUserData).length) ||
      !!this.getEditedUserData().length
    );
  }

  setInitialEditableUsersData() {
    const { fetchedUsers } = this;

    if (fetchedUsers) {
      this.editableUsersData = fetchedUsers.map(user => ({ ...user }));
    }
  }

  onToggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  saveChanges() {
    this.onToggleEditMode();
    this.sendChangedData().then(() => {
      this.setInitialEditableUsersData();
    });
  }

  openEntitlementForm() {
    this.shouldRenderEntitlementForm = true;
    this.shouldShowEntitlementForm = true;
  }

  createNewUser(entitlementData: EntitlementData) {
    this.newUserData = { ...this.newUserData, ...entitlementData };
    return axios
      .post("/api/newUser", this.newUserData, {
        headers: { "Content-Type": "application/json" }
      })
      .then(() => {
        this.loadData();
        this.transactionError = false;
        this.snackbar = true;
        this.transactionStatus =
          "Die Änderungen wurden erfolgreich eingetragen";
        this.shouldRenderEntitlementForm = false;
        this.shouldShowEntitlementForm = false;
        this.isCreateMode = false;
        this.newUserData = {} as NewUserData;
      })
      .catch(reason => this.showErrorSnackbar(reason))
      .finally(() => {
        this.setInitialEditableUsersData();
      });
  }

  userMailGenerator() {
    const {
      newUserData: { firstName, lastName }
    } = this;

    if (firstName && lastName) {
      this.newUserData.email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}@flavia-it.de`;
      this.$forceUpdate();
    }
  }

  getEditedUserData() {
    const { editableUsersData, fetchedUsers } = this;

    return editableUsersData
      .map(user => JSON.stringify(user))
      .filter(
        editedUserData =>
          !fetchedUsers
            .map(user => JSON.stringify(user))
            .includes(editedUserData)
      )
      .map(filteredUserData => JSON.parse(filteredUserData)) as User[];
  }

  discardChanges() {
    if (
      !this.hasUnsavedChanges() ||
      confirm("Die Eingaben werden gelöscht. Bestätigen?")
    ) {
      this.setInitialEditableUsersData();
      this.isEditMode = false;
    }
  }

  userSort() {
    return this.editableUsersData.sort(this.compareLastName);
  }

  compareLastName(firstPerson: User, secondPerson: User) {
    if (firstPerson.lastName < secondPerson.lastName) {
      return -1;
    }
    if (firstPerson.lastName > secondPerson.lastName) {
      return 1;
    }
    return 0;
  }

  discardNewUser() {
    if (
      !this.hasUnsavedChanges() ||
      confirm("Die Eingaben werden gelöscht. Bestätigen?")
    ) {
      this.newUserData = {} as NewUserData;
      this.isCreateMode = false;
      this.shouldRenderEntitlementForm = false;
    }
  }

  isSaveNewUserDisabled() {
    const {
      newUserData: { firstName, lastName, email, userRole }
    } = this;

    return !(firstName && lastName && email && userRole);
  }

  ajaxRequest(requestUrl: string, requestBody: User | User[]): Promise<void> {
    return this.axios
      .post(requestUrl, requestBody)
      .then(() => {
        this.loadData();
        this.transactionStatus = this.requestType;
        this.transactionError = false;
        this.snackbar = true;
      })
      .catch(reason => this.showErrorSnackbar(reason));
  }

  showErrorSnackbar(reason: any) {
    this.snackbar = true;
    this.transactionError = true;

    this.transactionStatus = getUserMessageForErrorReason(reason);
  }

  async sendChangedData(): Promise<void> {
    const editedUsersData = this.getEditedUserData();

    if (editedUsersData) {
      this.requestType = "Daten wurden aktualisiert";
      return await this.ajaxRequest("/api/changeUsersData", editedUsersData);
    }
  }

  closeSnackbar() {
    this.snackbar = false;
    this.transactionError = false;
    this.transactionStatus = "";
  }

  userHasWritePermissions(): boolean {
    return this.userMetaData.isAdmin || this.userMetaData.isManager;
  }
}
</script>

<style lang="scss">
.update-user-info-layout {
  margin-top: -30px;
  padding-top: 80px;
}

.change-menu,
.user-data-table {
  margin: 0 1.5%;
}

.user-data-table {
  border-left: 1px dotted;

  .v-datatable {
    &.v-table {
      tr {
        td {
          border-right: 1px dotted;
          text-align: left;
          border-bottom: 1px dotted;
          width: 35%;
          max-width: 5em;
        }

        th {
          border-right: 1px dotted;
          width: 35%;
        }

        th[role="columnheader"],
        td {
          padding: 0 2em;
        }
      }
    }
  }
}

.change-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  position: fixed;
  top: 95px;
  width: 97.05%;
  z-index: 1;
  padding-top: 30px;
  background-color: var(--v-primary-lighten5);
}

.users {
  text-align: left;
}

.user-name-update {
  display: flex;
  align-items: flex-start;
}

.new-user-data-item {
  margin-right: 2em;
}

.no-absence-date,
.loading-data-error {
  padding: 7.5em;
  font-size: calc(1rem + 2px);
}
</style>
