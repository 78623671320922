<template>
  <div class="forbidden-wrapper">
    <div
      class="forbidden-page"
      v-show="!userMetaData.isAdmin"
    >
      <h1>
        403 - Forbidden
      </h1>
      <v-img :src="selectedImage" class="forbidden-image"> </v-img>
      <div>
        <v-btn
          class="forbidden-button"
          depressed
          large
          color="primary"
          @click="$router.go(-2)"
          >Go back!</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue imports
import Vue from "vue";
import Component from "vue-class-component";

// Vuex store imports
import {
  AbsenceSpace,
  ABSENCE_DATA_ACTIONS,
  ABSENCE_DATA_GETTERS
} from "@/store/modules/absenceData";

// Model types
import { UserMetaData } from "@/models";

// Local component declarations
@Component
export default class Forbidden extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchUserMetaData)
  fetchUserMetaData!: () => Promise<UserMetaData>;

  notFoundImages = [
    require("../../assets/403/403_1.png"),
    require("../../assets/403/403_2.png"),
    require("../../assets/403/403_3.png"),
    require("../../assets/403/403_4.png")
  ];

  selectedImage = this.notFoundImages[
    Math.floor(Math.random() * this.notFoundImages.length)
    ];

  created() {
    const {
      userMetaData: { isAdmin },
      fetchUserMetaData
    } = this;

    isAdmin == undefined && fetchUserMetaData();
  }

  mounted() {
    const {
      userMetaData: { isAdmin }
    } = this;

    if (isAdmin) {
      this.$router.back();
    }
  }
}
</script>

<style scoped lang="scss">
.forbidden-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.forbidden-page {
  font-size: large;
  display: block;
}

.forbidden-image {
  position: relative;
  border: solid black;
  margin-left: auto;
  margin-right: auto;
  min-width: 40vw;
  max-width: 50vw;
}
</style>
