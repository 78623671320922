<template>
  <div class="breadcrumb-tab">
    <v-breadcrumbs :items="breadcrumbItems">
      <template v-slot:item="props">
        <div class="breadcrumb-items">
          <div>
            <v-btn
              fab
              class="prev-year"
              :disabled="prevYear < 2021"
              v-if="
                props.item.href.includes(
                  `/overview/year/${$route.params.year}`
                ) && !$route.params.month
              "
              icon
              small
              :to="{
                name: 'yearlyOverviewRoute',
                params: {
                  year: prevYear
                },
                query: {
                  selectedUser: $route.query.selectedUser
                }
              }"
            >
              <v-icon class="item-icon"> arrow_back </v-icon>
            </v-btn>

            <v-btn
              fab
              icon
              small
              class="prev-month"
              :disabled="prevYear < 2021"
              v-if="
                props.item.href.includes(
                  `/overview/month/${$route.params.year}`
                )
              "
              :to="{
                name: 'monthlyOverviewRoute',
                params: {
                  year: prevYear,
                  month: prevMonth
                },
                query: {
                  selectedUser: $route.query.selectedUser
                }
              }"
            >
              <v-icon class="item-icon"> arrow_back </v-icon>
            </v-btn>

            <v-btn
              fab
              icon
              small
              class="prev-year"
              :disabled="prevYear < 2021"
              v-if="
                props.item.href.includes(
                  `/yearlyEntitlement/${$route.params.year}`
                )
              "
              :to="{
                name: 'yearlyEntitlementRoute',
                params: {
                  year: prevYear
                },
                query: {
                  selectedUser: $route.query.selectedUser
                }
              }"
            >
              <v-icon class="item-icon"> arrow_back </v-icon>
            </v-btn>
          </div>

          <template>
            <router-link :to="props.item.href" class="breadcrumb">
              {{ props.item.text }}
            </router-link>
          </template>

          <div>
            <v-btn
              fab
              class="next-year"
              icon
              small
              v-if="
                props.item.href.includes(
                  `/overview/year/${$route.params.year}`
                ) && !$route.params.month
              "
              :to="{
                name: 'yearlyOverviewRoute',
                params: {
                  year: nextYear
                },
                query: {
                  selectedUser: $route.query.selectedUser
                }
              }"
            >
              <v-icon class="item-icon"> arrow_forward </v-icon>
            </v-btn>

            <v-btn
              fab
              icon
              class="next-month"
              v-if="
                props.item.href.includes(
                  `/overview/month/${$route.params.year}`
                )
              "
              small
              :to="{
                name: 'monthlyOverviewRoute',
                params: {
                  year: nextYear,
                  month: nextMonth
                },
                query: {
                  selectedUser: $route.query.selectedUser
                }
              }"
            >
              <v-icon class="item-icon">arrow_forward</v-icon>
            </v-btn>

            <v-btn
              fab
              icon
              small
              class="next-year"
              v-if="
                props.item.href.includes(
                  `/yearlyEntitlement/${$route.params.year}`
                )
              "
              :to="{
                name: 'yearlyEntitlementRoute',
                params: {
                  year: nextYear
                },
                query: {
                  selectedUser: $route.query.selectedUser
                }
              }"
            >
              <v-icon class="item-icon"> arrow_forward </v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script lang="ts">
// Vue imports
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

// Local type declarations
export interface BreadcrumbItem {
  text: string;
  disabled: boolean;
  href: string;
}

// Local component declarations
@Component
export default class BreadcrumbsItems extends Vue {
  nextYear = 0;
  nextMonth = 0;
  prevYear = 0;
  prevMonth = 0;
  breadcrumbItems: BreadcrumbItem[] = [];

  @Watch("$route.params")
  breadcrumbUpdate() {
    this.breadcrumbItems = [];
    this.setData();
    this.updateYearAndMonth();
  }

  updateYearAndMonth() {
    const currentRoute = this.$route.fullPath;
    const currentYear = parseInt(this.$route.params.year, 10);

    if (currentRoute.includes("overview/month")) {
      const currentMonth = parseInt(this.$route.params.month, 10);
      this.nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;
      this.nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
      this.prevYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      this.prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    } else if (
      currentRoute.includes("overview/year") ||
      currentRoute.includes("yearlyEntitlement")
    ) {
      this.prevYear = currentYear - 1;
      this.nextYear = currentYear + 1;
    }
  }

  setData() {
    const newBreadcrumbItems = [];
    const fullPath = this.$route.fullPath;
    const query = this.$route.fullPath.split("?")[1];
    if (fullPath.includes("overview")) {
      newBreadcrumbItems.push(
        {
          text: "Übersicht",
          disabled: false,
          href: "/overview/year"
        },
        {
          text: this.$route.params.year,
          disabled: false,
          href: `/overview/year/${this.$route.params.year}?${
            this.$route.fullPath.split("?")[1]
          }`
        }
      );
      if (this.$route.fullPath.includes("overview/month")) {
        newBreadcrumbItems.push({
          text: this.$route.params.month,
          disabled: false,
          href: `/overview/month/${this.$route.params.year}/${
            this.$route.params.month
          }?${query ? query : ""}`
        });
      }
    }
    if (this.$route.fullPath.includes("absenceRequests")) {
      newBreadcrumbItems.push({
        text: "Urlaubsanträge",
        disabled: false,
        href: "/absenceRequests"
      });
    } else if (this.$route.fullPath.includes("absence")) {
      newBreadcrumbItems.push({
        text: "Abwesenheit",
        disabled: false,
        href: `/absence`
      });
    }

    if (this.$route.fullPath.includes("workingHours")) {
      newBreadcrumbItems.push({
        text: "Soll-Arbeitszeiten",
        disabled: false,
        href: "/workingHours"
      });
    }

    if (this.$route.fullPath.includes("editEntitlement")) {
      newBreadcrumbItems.push({
        text: "Urlaubsanspruch bearbeiten",
        disabled: false,
        href: "/editEntitlement"
      });
    }
    if (this.$route.fullPath.includes("updateUserInformation")) {
      newBreadcrumbItems.push({
        text: "Personeninformationen aktualisieren",
        disabled: false,
        href: "/updateUserInformation"
      });
    }
    if (this.$route.fullPath.includes("changeHistory")) {
      newBreadcrumbItems.push({
        text: "Änderungshistorie",
        disabled: false,
        href: "/changeHistory"
      });
    }
    if (this.$route.fullPath.includes("yearlyEntitlement")) {
      newBreadcrumbItems.push(
        {
          text: "Jahres-Ansprüche",
          disabled: false,
          href: `/yearlyEntitlement`
        },
        {
          text: this.$route.params.year,
          disabled: false,
          href: `/yearlyEntitlement/${this.$route.params.year}`
        }
      );
    }
    this.breadcrumbItems = newBreadcrumbItems;
  }

  mounted() {
    this.setData();
    this.updateYearAndMonth();
  }
}
</script>

<style lang="scss">
.line-height-x2 {
  line-height: 2rem;
}

.breadcrumb-tab {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

.breadcrumb {
  color: whitesmoke;
}

.breadcrumb-items {
  display: flex;
  align-items: center;
}

.prev-month {
  position: relative;
  padding-right: 0.5em;
}

.next-month {
  position: relative;
  padding-left: 0.5em;
}

.item-icon {
  padding-top: 30%;
}
</style>
