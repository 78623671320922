<template>
  <div class="business-trips">
    <v-snackbar
      v-model="snackbar"
      :color="transactionError ? 'error' : 'success'"
      top
    >
      {{ transactionStatus }}
      <v-btn color="white" flat @click="onCloseSnackbar"> Schließen</v-btn>
    </v-snackbar>
    <div v-if="userMetaData.userId" class="business-trip-list">
      <div class="trip-filter-section">
        <user-filter
          v-if="userHasReadRights"
          class="user-filter"
          :user-request-accuracy="{ year: selectedYear }"
          :updateUserSelection="onChangeUsers"
          :contentIsLoaded="requestHandled"
        ></user-filter>

        <div class="status-and-year-filter">
          <v-autocomplete
            v-model="selectedReqStatuses"
            :search-input.sync="reqStatusSearchTerm"
            multiple
            ref="reqStatusFilter"
            label="Statusfilter"
            :items="BusinessTripStatuses"
            :filter="onSearchForReqStatus"
            @change="onChangeReqStatuses"
            :disabled="!requestHandled"
            class="status-filter"
            :no-data-text="PLACEHOLDER_NO_SELECTABLE_DATA"
            hide-selected
          >
            <template v-slot:selection="data">
              <v-chip
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="() => onClearSelectedReqStatus(data.item)"
              >
                {{ getFormattedRequestStatusOption(data.item) }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              {{ getFormattedRequestStatusOption(data.item) }}
            </template>
          </v-autocomplete>

          <v-text-field
            type="number"
            class="year-filter"
            v-model="selectedYear"
            label="Jahr"
            @change="updateDataAfterChange"
          >
          </v-text-field>
        </div>
      </div>

      <v-expansion-panel
        v-if="businessTrips.length"
        expand
        :value="getArrayOfPreopenedRequests()"
      >
        <v-expansion-panel-content
          v-for="trip of businessTrips"
          :key="trip.businessTripId"
          :data-employee-id="trip.employeeId"
          :data-business-trip-id="trip.businessTripId"
          :id="trip.businessTripId"
          :ref="trip.businessTripId"
        >
          <template v-slot:header>
            <div
              class="expandable-header"
              :class="header.className"
              v-for="(header, i) of getExpandableHeaderData(trip)"
              :key="i"
            >
              <div class="expandable-header-column">
                <div class="header-title">{{ header.title }}</div>
                <div class="header-value">{{ header.value }}</div>
              </div>
            </div>
          </template>

          <div class="expandable-body">
            <div class="request-status" v-if="isOnMobileBrowser()">
              <div class="bold">Status</div>
              <div>{{ getStatus(trip) }}</div>
            </div>
            <div class="expandable-body-column absence-request-note">
              <span>Zweck:</span>
              {{ trip.purpose }}
            </div>
          </div>
          <div
            class="cancel-processed-button"
            v-if="
              trip.state === 'ACCEPTED' ||
                trip.state === 'DECLINED' ||
                trip.state === 'CANCELLATION_REQUESTED'
            "
          >
            <v-btn
              depressed
              color="error"
              v-if="userIsAdmin"
              v-on:click="() => cancelBusinessTrip(trip, false)"
            >
              Antrag Stornieren
            </v-btn>
            <v-btn
              depressed
              color="error"
              :disabled="trip.state === 'CANCELLATION_REQUESTED'"
              v-else
              v-on:click="() => cancelBusinessTrip(trip, true)"
            >
              Stornierung beantragen
            </v-btn>
          </div>
          <div
            class="expandable-body"
            v-for="(body, recipientIndex) of getExpandableBodyData(trip)"
            :data-recipient-id="trip.recipients[recipientIndex].recipientId"
            :key="recipientIndex"
          >
            <div
              class="expandable-body-column"
              v-for="(entry, k) of body"
              :class="entry.className"
              :key="k"
            >
              <div v-if="entry.className === 'send-reminder-btn'">
                <v-tooltip top :disabled="entry.value === ''">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        elevation="2"
                        :disabled="entry.value !== ''"
                        v-on:click="
                          () =>
                            sendBusinessTripReminder(
                              trip.recipients[recipientIndex]
                            )
                        "
                      >
                        {{
                          isOnMobileBrowser()
                            ? "Erinnern"
                            : "Erinnerungsmail schicken"
                        }}
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ entry.value }}</span>
                </v-tooltip>
              </div>
              <div v-else>{{ entry.value }}</div>
            </div>
          </div>
          <div class="expandable-body buttons">
            <div class="process-buttons" v-if="canBeProcessed(trip)">
              <v-btn
                depressed
                color="info"
                v-on:click="() => processBusinessTrip(trip, true)"
              >
                Akzeptieren
              </v-btn>
              <v-btn
                depressed
                color="info"
                v-on:click="() => processBusinessTrip(trip, false)"
              >
                Ablehnen
              </v-btn>
            </div>
            <div class="cancel-request-btn" v-if="canBeProcessed(trip)">
              <v-btn
                depressed
                color="error"
                v-on:click="() => cancelBusinessTrip(trip, false)"
              >
                Antrag Stornieren
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <div v-else>
        <div id="loadingSpinner" v-if="!requestHandled"></div>
        <div v-else-if="userMetaData.userId">
          <div class="no-absence-date" v-if="!errorLoadingData">
            <strong>
              Es gibt keine Einträge für die ausgewählten Filterkriterien
            </strong>
          </div>
          <div v-else class="loading-data-error">
            <strong>
              Beim Versuch die Daten zu laden ist ein Fehler aufgetreten:
              {{ errorLoadingData }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import de from "date-fns/locale/de";

import UserFilter from "../common/userFilter.vue";
import {
  BusinessTripStatuses,
  DATE_WITH_WEEKDAY_DATEFORMAT,
  PLACEHOLDER_NO_SELECTABLE_DATA,
  STATUS_ACCEPTED,
  STATUS_CANCELLATION_REQUESTED,
  STATUS_CANCELLED,
  STATUS_DECLINED,
  STATUS_REQUESTED,
  statusNamesByKey
} from "@/constants";
import {BusinessTrip, BusinessTripRecipient, BusinessTripState, RecipientState, User, UserMetaData} from "@/models";
import {ABSENCE_DATA_ACTIONS, ABSENCE_DATA_GETTERS, AbsenceSpace} from "@/store/modules/absenceData";
import {formatDateToFormatStringInUTCTz, getDateContext, getUserMessageForErrorReason, hasReadRights} from "@/utils";
import {differenceInHours, formatDistanceToNowStrict} from "date-fns";

type ExpandableHeader = Array<{
  title: string;
  value: string;
  className: string;
}>;
type ExpandableBody = Array<{ className: string; value: string | boolean }>;

interface DataTableItemEntry {
  disabled: boolean;
  date: string;
  month: string;
  class: string;
  isNew: boolean;
  isChanged: boolean;
  selectedValue: string;
  initialValue: string;
}

@Component({
  components: { userFilter: UserFilter }
})
export default class BusinessTripsOverview extends Vue {
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getUserMetaData)
  userMetaData!: UserMetaData;
  @AbsenceSpace.Action(ABSENCE_DATA_ACTIONS.fetchAllUsers)
  fetchAllUsers!: () => Promise<User[]>;
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getAllUsers)
  users!: User[];
  @AbsenceSpace.Getter(ABSENCE_DATA_GETTERS.getSelectedUsers)
  getSelectedUsers!: User[];

  businessTrips: BusinessTrip[] = [];

  requestHandled: boolean = false;
  errorLoadingData = null;

  filterUsers: string[] = [];
  userSearchTerm = "";

  selectedReqStatuses: string[] =
    this.getStatesFromQuery() == null
      ? [STATUS_REQUESTED, STATUS_CANCELLATION_REQUESTED]
      : this.getStatesFromQuery()!;
  reqStatusSearchTerm = "";

  selectedYear: string =
    this.getYearFromQuery() || new Date().getFullYear().toString();

  transactionStatus = "";
  snackbar = false;
  transactionError = false;

  selectedDateContext: Date | null = null;

  PLACEHOLDER_NO_SELECTABLE_DATA = PLACEHOLDER_NO_SELECTABLE_DATA;
  BusinessTripStatuses = BusinessTripStatuses;

  created() {
    Promise.all([this.fetchAllUsers(), this.fetchBusinessTrips()]).finally(
      () => (this.requestHandled = true)
    );
  }

  updated() {
    const hash = this.getHash();

    if (hash.length === 0) {
      return;
    }

    const requestElement = document.getElementById(hash);

    // Wait for the expansion panel to expand fully
    if (requestElement) {
      setTimeout(function() {
        requestElement.scrollIntoView();
      }, 200);
    }
  }

  onCloseSnackbar() {
    this.snackbar = false;
    this.transactionStatus = "";
  }

  onSearchForReqStatus(reqStatus: BusinessTripState, queryText: string) {
    return this.getFormattedRequestStatusOption(reqStatus)
      .toLocaleLowerCase()
      .includes(queryText.toLowerCase());
  }

  onChangeUsers() {
    this.updateDataAfterChange();
  }

  onChangeReqStatuses() {
    this.reqStatusSearchTerm = "";
    this.updateDataAfterChange();
  }

  onClearSelectedReqStatus(reqStatus: BusinessTripState) {
    this.selectedReqStatuses = this.selectedReqStatuses.filter(
      status => status !== reqStatus
    );
    this.updateDataAfterChange();
  }

  processBusinessTrip(businessTrip: BusinessTrip, answer: boolean) {
    const { businessTripId, startDate, state } = businessTrip;
    console.log(businessTrip);
    if (
      state === STATUS_REQUESTED &&
      !confirm(
        `Diese Geschäftsreise wurde noch nicht von allen angegebenen Ansprechpartnern beantwortet. Trotzdem ${
          answer ? "akzeptieren" : "ablehnen"
        }?`
      )
    ) {
      return;
    }

    const requestUrl = answer
      ? `/api/business-trips/${businessTripId}/accept`
      : `/api/business-trips/${businessTripId}/decline`;

    this.axios
      .post(requestUrl)
      .then(() => this.updateDataAfterChange())
      .catch(error => {
        this.showUserNotification(getUserMessageForErrorReason(error), true);
      });

    this.selectedDateContext = getDateContext(startDate);
  }

  getFormattedRequestStatusOption(reqStatus: BusinessTripState) {
    return statusNamesByKey[reqStatus];
  }

  getExpandableHeaderData(businessTrip: BusinessTrip): ExpandableHeader {
    const extraSpace = !this.userHasReadRights ? " with-extra-space" : "";

    const headerData = [
      ...(this.userHasReadRights
        ? [
          {
            title: "Bearbeitet von",
            value: this.getFormattedUserTitle(
              (
                this.users.find(
                  user => user.userId === businessTrip.employeeId
                ) || {}
              ).email as string
            ),
            className: "posted-by"
          }
        ]
        : []),
      {
        title: "Gestellt Am",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(businessTrip.createdAt),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `posted-on${extraSpace}`
      },
      {
        title: "Von",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(businessTrip.startDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `from-date${extraSpace}`
      },
      {
        title: "Bis",
        value: formatDateToFormatStringInUTCTz(
          getDateContext(businessTrip.endDate),
          DATE_WITH_WEEKDAY_DATEFORMAT
        ),
        className: `to-date${extraSpace}`
      }
    ];

    if (!this.isOnMobileBrowser()) {
      headerData.push({
        title: "Status",
        value: this.getStatus(businessTrip),
        className: "request-status"
      });
    }

    return headerData;
  }

  getStatus(businessTrip: BusinessTrip) {
    return this.getFormattedRequestStatusOption(businessTrip.state);
  }

  getExpandableBodyData({ recipients = [] }: BusinessTrip): ExpandableBody[] {
    return recipients.map(({ email, state, lastReminder }) => {
      const entry = [
        {
          className: "recipient",
          value: this.getFormattedUserTitle(email)
        },
        {
          className: "last-reminder",
          value: this.getFormattedReminderData(
            statusNamesByKey[state],
            lastReminder
          )
        },
        {
          className: "send-reminder-btn",
          value: this.getReminderTooltip(state, lastReminder)
        }
      ];

      if (!this.isOnMobileBrowser()) {
        entry.push({
          className: "recipient-status",
          value: statusNamesByKey[state]
        });
      }

      return entry;
    });
  }

  getFormattedReminderData(
    recipientStatus: string,
    lastReminderTime: number = 0
  ) {
    if (!lastReminderTime) {
      return "Es gibt keine Angaben!";
    }
    // @ts-ignore
    const fromNow = formatDistanceToNowStrict(
      getDateContext(lastReminderTime),
      {
        // @ts-ignore
        locale: de,
        formatDistance: "minutes"
      }
    );
    return `${recipientStatus} ${fromNow}`;
  }

  getFormattedUserTitle(recipientEmail: string) {
    const existingUser = this.users.find(
      ({ email }) => email === recipientEmail
    );

    return existingUser
      ? `${existingUser.firstName} ${existingUser.lastName}`
      : recipientEmail;
  }

  getStatesFromQuery() {
    const urlParameters = new URLSearchParams(window.location.search);

    const stateParameter = urlParameters.get("state");

    if (stateParameter == null) {
      return null;
    }

    return stateParameter.split(",");
  }

  getYearFromQuery() {
    const urlParameters = new URLSearchParams(window.location.search);

    return urlParameters.get("year");
  }

  canBeProcessed({ employeeId, state }: BusinessTrip = {} as BusinessTrip) {
    const {
      userMetaData: { userId }
    } = this;

    return (
      (employeeId === userId || this.userMetaData.isAdmin) &&
      state !== STATUS_CANCELLED &&
      state !== STATUS_CANCELLATION_REQUESTED &&
      state !== STATUS_ACCEPTED &&
      state !== STATUS_DECLINED
    );
  }

  getArrayOfPreopenedRequests(): boolean[] {
    const openedRequests: boolean[] = [];
    const hash = this.getHash();

    for (const request of this.businessTrips) {
      openedRequests.push(hash.length !== 0 && hash === request.businessTripId);
    }

    if (hash.length !== 0 && openedRequests.every(value => !value)) {
      this.transactionError = true;
      this.transactionStatus =
        "Diese Geschäftsreise konnte nicht gefunden werden";
      this.snackbar = true;
    }

    return openedRequests;
  }

  getHash() {
    return window.location.hash.substring(1);
  }

  fetchBusinessTrips(): Promise<BusinessTrip[]> {
    this.requestHandled = false;
    const requestFilter = {
      userIds: this.getSelectedUsers.map(user => user.userId),
      states: this.selectedReqStatuses,
      year: this.selectedYear
    };

    return this.axios
      .post("/api/business-trips/get-all", requestFilter)
      .then(response => (this.businessTrips = response.data))
      .catch(error => (this.errorLoadingData = error));
  }

  updateDataAfterChange() {
    this.fetchBusinessTrips().finally(() => (this.requestHandled = true));
  }

  sendBusinessTripReminder(recipient: BusinessTripRecipient) {
    return this.axios
      .post(
        `/api/business-trips/${recipient.businessTripId}/recipients/${recipient.recipientId}/remind`
      )
      .then(() => {
        this.showUserNotification("Erinnerungsmail versendet an " + recipient.email, false);
        this.updateDataAfterChange()
      })
      .catch(error => {
        this.showUserNotification(getUserMessageForErrorReason(error), true);
      });
  }

  getReminderTooltip(state: RecipientState, lastReminder: number): string {
    if (state !== STATUS_REQUESTED) {
      return "EmpfängerIn hat den Antrag bereits beantwortet";
    }

    if (differenceInHours(new Date(), new Date(lastReminder)) < 24) {
      return "Die letzte Mail wurde vor weniger als 24 Stunden geschickt";
    }

    return "";
  }

  cancelBusinessTrip(
    businessTrip: BusinessTrip,
    requestCancellation: boolean = false
  ) {
    const confirmationMessage = requestCancellation
      ? "Soll eine Stornierung beantragt werden?"
      : "Soll dieser Antrag wirklich storniert werden?";
    const postEndpoint = requestCancellation
      ? `/api/business-trips/${businessTrip.businessTripId}/cancel/request`
      : `/api/business-trips/${businessTrip.businessTripId}/cancel`;

    if (!confirm(confirmationMessage)) {
      return;
    }

    return this.axios
      .post(postEndpoint)
      .then(() => this.updateDataAfterChange())
      .catch(error => {
        this.showUserNotification(getUserMessageForErrorReason(error), true);
      });
  }

  showUserNotification(message: string, isError: boolean) {
    this.transactionStatus = message;
    this.transactionError = isError;
    this.snackbar = true;
  }

  get userHasReadRights(): boolean {
    return hasReadRights(this.userMetaData);
  }

  get userIsAdmin(): boolean {
    return this.userMetaData.isAdmin;
  }
}
</script>

<style lang="scss">
.business-trips {
  overflow-x: auto;

  .v-expansion-panel {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
}

.business-trip-section {
  margin-bottom: 2.5%;
}

.business-trip-list {
  padding: 2.5%;
}

.user-filter {
  margin-bottom: 1%;
}

.status-and-year-filter {
  display: flex;

  .status-filter {
    flex: 0 0 75%;
    margin-right: 5%;
  }

  .year-filter {
    margin-top: 12px;
  }
}

.no-trip-date,
.loading-data-error {
  padding: 7.5em;
  font-size: calc(1rem + 2px);
}

.v-expansion-panel__container {
  /* stylelint-disable */
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  /* stylelint-enable */
  margin-bottom: 0.25%;

  .bold {
    font-weight: bold;
  }

  @media (max-width: 750px) {
    .request-status {
      flex: 0 0 100%;
      padding: 16px 12px;
    }
  }

  .expandable-header {
    &.posted-by,
    &.posted-on,
    &.from-date,
    &.to-date {
      flex: 0 0 22%;

      &.with-extra-space {
        flex: 0 0 29%;
      }
    }

    &.request-status {
      flex: 0 0 10%;
    }

    .header-title {
      font-weight: bold;
      padding-bottom: 5.5%;
    }
  }

  .expandable-header,
  .expandable-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .expandable-body {
    padding: 0 24px;
    align-items: center;
    border-top: 1px solid lightgrey;
  }

  .expandable-body-column {
    flex: 0 0 25%;

    &.business-trip-purpose {
      flex: 0 0 100%;
      padding: 16px 12px;

      span {
        display: block;
        font-weight: bold;
        margin-right: 1.5%;
      }
    }

    @media (max-width: 750px) {
      &.business-trip-purpose {
        flex: 0 0 100%;
        padding: 16px 12px;

        span {
          display: block;
          font-weight: bold;
          margin-right: 1.5%;
        }
      }
    }
  }

  .cancel-processed-button {
    display: flex;
    justify-content: flex-end;
    padding: 16px 12px;
  }

  .recipient-status {
    flex: 0 0 20%;
  }

  .buttons {
    .process-buttons,
    .cancel-request-btn {
      padding: 12px 0;
    }

    .cancel-request-btn {
      justify-content: flex-end;
      order: 2;
      flex: 0 0 20%;
    }

    .process-buttons {
      justify-content: flex-start;
      order: 1;
      margin-right: 2.5%;
    }
  }

  .expandable-header-column,
  .expandable-body-column {
    padding: 12px;
  }
}
</style>
