var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"breadcrumb-tab"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('div',{staticClass:"breadcrumb-items"},[_c('div',[(
              props.item.href.includes(
                `/overview/year/${_vm.$route.params.year}`
              ) && !_vm.$route.params.month
            )?_c('v-btn',{staticClass:"prev-year",attrs:{"fab":"","disabled":_vm.prevYear < 2021,"icon":"","small":"","to":{
              name: 'yearlyOverviewRoute',
              params: {
                year: _vm.prevYear
              },
              query: {
                selectedUser: _vm.$route.query.selectedUser
              }
            }}},[_c('v-icon',{staticClass:"item-icon"},[_vm._v(" arrow_back ")])],1):_vm._e(),(
              props.item.href.includes(
                `/overview/month/${_vm.$route.params.year}`
              )
            )?_c('v-btn',{staticClass:"prev-month",attrs:{"fab":"","icon":"","small":"","disabled":_vm.prevYear < 2021,"to":{
              name: 'monthlyOverviewRoute',
              params: {
                year: _vm.prevYear,
                month: _vm.prevMonth
              },
              query: {
                selectedUser: _vm.$route.query.selectedUser
              }
            }}},[_c('v-icon',{staticClass:"item-icon"},[_vm._v(" arrow_back ")])],1):_vm._e(),(
              props.item.href.includes(
                `/yearlyEntitlement/${_vm.$route.params.year}`
              )
            )?_c('v-btn',{staticClass:"prev-year",attrs:{"fab":"","icon":"","small":"","disabled":_vm.prevYear < 2021,"to":{
              name: 'yearlyEntitlementRoute',
              params: {
                year: _vm.prevYear
              },
              query: {
                selectedUser: _vm.$route.query.selectedUser
              }
            }}},[_c('v-icon',{staticClass:"item-icon"},[_vm._v(" arrow_back ")])],1):_vm._e()],1),[_c('router-link',{staticClass:"breadcrumb",attrs:{"to":props.item.href}},[_vm._v(" "+_vm._s(props.item.text)+" ")])],_c('div',[(
              props.item.href.includes(
                `/overview/year/${_vm.$route.params.year}`
              ) && !_vm.$route.params.month
            )?_c('v-btn',{staticClass:"next-year",attrs:{"fab":"","icon":"","small":"","to":{
              name: 'yearlyOverviewRoute',
              params: {
                year: _vm.nextYear
              },
              query: {
                selectedUser: _vm.$route.query.selectedUser
              }
            }}},[_c('v-icon',{staticClass:"item-icon"},[_vm._v(" arrow_forward ")])],1):_vm._e(),(
              props.item.href.includes(
                `/overview/month/${_vm.$route.params.year}`
              )
            )?_c('v-btn',{staticClass:"next-month",attrs:{"fab":"","icon":"","small":"","to":{
              name: 'monthlyOverviewRoute',
              params: {
                year: _vm.nextYear,
                month: _vm.nextMonth
              },
              query: {
                selectedUser: _vm.$route.query.selectedUser
              }
            }}},[_c('v-icon',{staticClass:"item-icon"},[_vm._v("arrow_forward")])],1):_vm._e(),(
              props.item.href.includes(
                `/yearlyEntitlement/${_vm.$route.params.year}`
              )
            )?_c('v-btn',{staticClass:"next-year",attrs:{"fab":"","icon":"","small":"","to":{
              name: 'yearlyEntitlementRoute',
              params: {
                year: _vm.nextYear
              },
              query: {
                selectedUser: _vm.$route.query.selectedUser
              }
            }}},[_c('v-icon',{staticClass:"item-icon"},[_vm._v(" arrow_forward ")])],1):_vm._e()],1)],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }