<template>
  <v-footer height="auto" color="primary lighten-4">
    <v-layout column larger-font>
      <v-layout justify-center row>
        <v-flex>
          <strong class="line-height-x2">Zeichenerklärung:</strong>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex>
          <div>A = Ausgleich(Zeitausgleich)</div>
          <div>F = Feiertag</div>
        </v-flex>
        <v-flex>
          <div>K = Krank</div>
          <div>KK = Kindkrank</div>
        </v-flex>
        <v-flex>
          <div>EZ = Elternzeit</div>
          <div>S = Sonderurlaub</div>
        </v-flex>
        <v-flex>
          <div>U = Urlaub ganzer Tag</div>
          <div>u = halber Tag</div>
          <div>UAW = unbezahlte Abwesenheit</div>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-footer>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class LegendFooter extends Vue {}
</script>

<style lang="scss">
.line-height-x2 {
  line-height: 2rem;
}
</style>
