import axios from "axios";

const securityURL = "/security/checklogin/";

axios.interceptors.response.use(
  response => {
    // all 2xx/3xx responses will end here
    return response;
  },
  error => {
    // all 4xx/5xx responses will end here

    if (
      error.code === "ERR_NETWORK" &&
      error.config.url === "/api/getUserMeta"
    ) {
      const variable = encodeURIComponent(window.location.hash);
      const referral = "referral="+encodeURIComponent(window.location.pathname +"?" + window.location.search);
      const redirectUrl = `${securityURL}?${referral}${variable}`
      console.warn("Redirecting to:", redirectUrl);
      window.location.href = redirectUrl;
      return Promise.reject(
        "no active session found (user is not authenticated)"
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
